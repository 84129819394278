import { Box, BoxProps, Button, ButtonProps, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Typography } from 'lib/components';
import NotAcceptedModal from 'lib/components/ErrorModals/NotAcceptedModal';
import Order, { OrderSchedule } from 'models/Order';
import moment from 'moment';
import React, { ComponentProps } from 'react';
import { useDrop } from 'react-dnd';
import Rnd, { DraggableData, RndDragCallback } from 'react-rnd';
import { useAppDispatch } from 'store';
import { default as employeeAsyncActions } from 'store/actions/employees.action';
import {
	useEmployeesState,
	useMultidragState,
	useOrderPhasesState,
	useOrdersState,
	useSchedulerState,
	useSearchState,
	useUIState,
} from 'store/selectors';
import { DragItemTypes, OrdersState } from 'store/types';
import Employee from '../../../../models/Employee';
import { IcNext, IcRemove } from '../../../../res/icons';
import { employeesActions } from '../../../../store/slices/employees.slice';
import { multidragActions } from '../../../../store/slices/multidrag.slice';
import { ordersActions } from '../../../../store/slices/orders.slice';
import { schedulerActions } from '../../../../store/slices/scheduler.slice';
import { uiActions } from '../../../../store/slices/ui.slice';
import generateStartAt from '../../../generateStartAt';
import getWorkingHours from '../../../getWorkingHours';
import invertColor from '../../../invertColor';
import isOverlapping from '../../../isOverlapping';

interface DroppableScheduleBoxProps {
	style: React.CSSProperties;
	scheduleId: string;
	date: string;
	dayIndex: number;
	scrollX?: number;
	scheduleBoxRef?: React.RefObject<HTMLDivElement>;
}

export const DropableScheduleBox: React.FC<DroppableScheduleBoxProps> = ({
	children,
	style,
	date,
	scheduleId,
	dayIndex,
	scrollX = 0,
	scheduleBoxRef,
}) => {
	const dispatch = useAppDispatch();

	const uiState = useUIState();
	const schedulerState = useSchedulerState();
	const employeesState = useEmployeesState();
	const ordersState = useOrdersState();
	const searchState = useSearchState();
	const multidragState = useMultidragState();

	const [isErrorModalOpen, setIsErrorModalOpen] = React.useState<boolean>(false);
	const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

	const handleCloseErrorModal = React.useCallback(() => {
		setIsErrorModalOpen(false);
		setTimeout(() => {
			setErrorMessage(null);
		}, 1000);
	}, []);

	const [{ isOver }, drop] = useDrop(
		() => ({
			canDrop: () => schedulerState.schedulerResolution.mode !== 'days',
			accept: DragItemTypes.WORK_ORDER,
			drop: (item: Pick<Order, 'id'>, monitor) => {
				const offset = monitor.getSourceClientOffset();
				if (!offset) {
					return;
				}

				let offsetX = offset.x - (uiState.calenderVisible ? 450 : 200) - 30;
				if (offsetX < 0) {
					offsetX = 0;
				}

				let posX = Math.floor((offsetX + scrollX) / schedulerState.schedulerResolution.oneMinuteWidth);

				let hour = 0;
				let minute = 0;

				while (posX >= 60) {
					posX -= 60;

					hour++;
				}
				minute = Math.floor(posX);

				// Snap to next hour.
				if (minute >= 57) {
					hour++;
					minute = 0;
				}

				if (minute <= 3) {
					minute = 0;
				} else if (minute >= 12 && minute <= 18) {
					minute = 15;
				} else if (minute >= 27 && minute <= 33) {
					minute = 30;
				} else if (minute >= 42 && minute <= 48) {
					minute = 45;
				}

				let ids: Array<string> = [item.id];
				console.log('ids:', ids);
				if (multidragState.list.length > 0) {
					ids = [...multidragState.list];
				}

				const employee = employeesState.list.find((element) => element.id === scheduleId)!;

				const startAtMoment = moment.utc(date, 'YYYY-MM-DD').hour(hour).minute(minute);
				let workingHours = getWorkingHours(employee, startAtMoment.format('ddd'));

				if (startAtMoment.hour() < workingHours.from[0]) {
					startAtMoment.hour(workingHours.from[0]).minute(workingHours.from[1]);
				}

				const momentIndexDate = moment.utc(date, 'YYYY-MM-DD');
				const workingFrom = momentIndexDate.clone().hour(workingHours.from[0]).minute(workingHours.from[1]);
				const workingTo = momentIndexDate.clone().hour(workingHours.to[0]).minute(workingHours.to[1]);

				const startAtMomentString = startAtMoment.toISOString();
				const workingFromString = workingFrom.toISOString();
				const workingToString = workingTo.toISOString();

				if (startAtMomentString < workingFromString) {
					setErrorMessage("You can't assign order before shift start");
					return;
				}
				if (startAtMomentString > workingToString) {
					setErrorMessage("You can't assign order after shift end");
					return;
				}

				if (anyOrderisOverlapping(ids, ordersState, startAtMoment, employee)) {
					setErrorMessage("You cannot schedule this order here, because it's overlapping with another one.");
					return;
				}

				dispatch(schedulerActions.setIsDragging(false));
				dispatch(
					employeeAsyncActions.assignOrder({
						employeeId: scheduleId,
						ids,
						startAt: moment.utc(date, 'YYYY-MM-DD').hour(hour).minute(minute).format(),
					})
				);
				dispatch(ordersActions.scheduleByIds(ids));
				dispatch(multidragActions.resetSelectedOrders());

				const allOrdersAreDraggedAndAssigned = searchState.list.documents.length === ids.length;
				if (allOrdersAreDraggedAndAssigned) {
					dispatch(ordersActions.refresh());
				}
			},
			hover: (_, monitor) => {
				const position = monitor.getClientOffset();
				const width = scheduleBoxRef?.current?.clientWidth as number;

				let endPos =
					width < 400
						? width + 499 - width * 0.3
						: width < 800
						? width + 499 - width * 0.2
						: width + 499 - width * 0.15;

				const startPos = 499 + width * 0.05;

				if ((position?.x as number) >= endPos) {
					scheduleBoxRef?.current?.scrollBy({
						left: width * 0.03,
						behavior: 'smooth',
					});
				}
				if ((position?.x as number) <= startPos) {
					scheduleBoxRef?.current?.scrollBy({
						left: -width * 0.03,
						behavior: 'smooth',
					});
				}
			},
			collect: (monitor) => ({
				isOver: monitor.isOver(),
			}),
		}),
		[scheduleId, date, multidragState, scrollX, schedulerState.schedulerResolution]
	);

	React.useEffect(() => {
		if (errorMessage !== null && !isErrorModalOpen) {
			setIsErrorModalOpen(true);
		}
	}, [errorMessage]);

	return (
		<div ref={drop}>
			<NotAcceptedModal message={errorMessage} onClose={handleCloseErrorModal} open={isErrorModalOpen} />
			<ScheduleBoxItem dayIndex={dayIndex} style={{ ...style, border: isOver ? '1px solid blue' : '' }}>
				{children}
			</ScheduleBoxItem>
		</div>
	);
};

export const anyOrderisOverlapping = function (
	ids: string[],
	ordersState: OrdersState,
	startAtMoment: moment.Moment,
	employee: Employee
) {
	let yesIsOverlapping = false;

	for (const id of ids) {
		const order = ordersState.list.find((element) => element.id === id)!;

		const startAtList = generateStartAt(startAtMoment, order, employee);

		for (let i = 0; i < startAtList.length - 1; i += 2) {
			const tempStartAt = moment.utc(startAtList[i]);
			const tempEndAt = moment.utc(startAtList[i + 1]);

			if (isOverlapping('-1', employee.orders, tempStartAt, tempEndAt)) {
				yesIsOverlapping = true;
				break;
			}
		}
	}
	return yesIsOverlapping;
};

export const ScheduleBoxItem = styled(
	({ children, dayIndex, ...restProps }: ComponentProps<'div'> & { dayIndex: number }) => {
		const schedulerState = useSchedulerState();

		let minWidth =
			dayIndex === 0
				? schedulerState.schedulerResolution.boxWidth * schedulerState.schedulerResolution.boxCount + 30
				: schedulerState.schedulerResolution.boxWidth * schedulerState.schedulerResolution.boxCount;
		if (schedulerState.schedulerResolution.mode === 'days') {
			minWidth =
				dayIndex === 0
					? schedulerState.schedulerResolution.boxWidth + 30
					: schedulerState.schedulerResolution.boxWidth;
		}

		return (
			<div {...restProps}>
				{/* <div className="left"></div> */}
				<div
					className="main"
					style={{
						minWidth,
					}}
				>
					{children}
				</div>
				{schedulerState.schedulerResolution.mode !== 'days' && <div className="right"></div>}
			</div>
		);
	}
)(() => {
	const schedulerState = useSchedulerState();

	return {
		// overflow: 'hidden',
		display: 'flex',
		height: 36,
		minWidth:
			schedulerState.schedulerResolution.mode === 'days'
				? schedulerState.schedulerResolution.boxWidth
				: `calc(${schedulerState.schedulerResolution.boxWidth}px * ${schedulerState.schedulerResolution.boxCount})`,
		alignItems: 'stretch',
		'& > .left, & > .right': {
			flexBasis: 30,
			minWidth: 30,
			border: '0.5px solid #dddfe2',
		},
		'& > .main': {
			minWidth: schedulerState.schedulerResolution.boxWidth * schedulerState.schedulerResolution.boxCount,
			position: 'relative',
			border: '0.5px solid #dddfe2',
			padding: '5px 0',
		},
	};
});

interface WorkOrderProps extends BoxProps {
	employee: Employee;
	date: string;
	dateIndex: number;
	rowIndex: number;
	workOrder?: OrderSchedule;
	onStop?: RndDragCallback;
	handleWorkDialogOpen?: () => void;
}

// let startTime = 0;

const DeleteButton = styled(IconButton)`
	fontsize: 1.2rem;
	opacity: 0;
	:hover {
		opacity: 1;
	}
`;

export const WorkOrder = styled(
	({
		employee,
		date,
		dateIndex,
		rowIndex,
		workOrder,
		onStop,
		handleWorkDialogOpen,
	}: // ...restProps
	WorkOrderProps) => {
		const dispatch = useAppDispatch();

		const ref = React.useRef(null);

		const schedulerState = useSchedulerState();
		const ordersState = useOrdersState();
		const orderPhasesState = useOrderPhasesState();
		const uiState = useUIState();

		const [disableDragging, setDisableDragging] = React.useState<boolean>(true);
		const [hasNext, setHasNext] = React.useState<boolean>(false);

		const handleClick = (e: any) => {
			e.preventDefault();
			if (handleWorkDialogOpen) {
				handleWorkDialogOpen();
			}
		};
		const handleDelete = () => {
			if (!workOrder) {
				return;
			}

			dispatch(
				employeesActions.removeOrderSchedule({
					employeeId: employee.id,
					scheduleId: workOrder.id,
				})
			);
			dispatch(
				employeeAsyncActions.unassignOrder({
					id: employee.id,
					orderId: workOrder.orderId,
				})
			);
			dispatch(ordersActions.unassignOrder({ orderId: workOrder.orderId }));
			dispatch(ordersActions.unScheduleByIds([workOrder.orderId]));
		};

		const x = React.useMemo<number>(() => {
			if (!workOrder) {
				return 0;
			}

			const dates = workOrder.startAt.split(',');

			const momentIndexDate = moment.utc(date, 'YYYY-MM-DD');
			const findDate = dates.find((element) => element.includes(momentIndexDate.format('YYYY-MM-DD')));
			if (!findDate) {
				return dateIndex === 0 ? 30 : 0;
			}

			const startAt = moment.utc(findDate);

			let hour = startAt.hour() * schedulerState.schedulerResolution.oneHourWidth;
			let minute = startAt.minute() * schedulerState.schedulerResolution.oneMinuteWidth;

			// 30px is the left scroll button width;
			return (dateIndex === 0 ? 30 : 0) + hour + minute;
		}, [dateIndex, workOrder, date, schedulerState.schedulerResolution]);

		const startWidth = React.useMemo<number>(() => {
			const momentIndexDate = moment.utc(date, 'YYYY-MM-DD');
			const workingHours = getWorkingHours(employee, momentIndexDate.format('ddd'));

			let hour = workingHours.from[0] * schedulerState.schedulerResolution.oneHourWidth;
			let minute = workingHours.from[1] * schedulerState.schedulerResolution.oneMinuteWidth;

			return hour + minute;
		}, [employee, date, schedulerState.schedulerResolution]);

		const xWidth = React.useMemo<number>(() => {
			const momentIndexDate = moment.utc(date, 'YYYY-MM-DD');
			const workingHours = getWorkingHours(employee, momentIndexDate.format('ddd'));

			let hour = workingHours.to[0] * schedulerState.schedulerResolution.oneHourWidth;
			let minute = workingHours.to[1] * schedulerState.schedulerResolution.oneMinuteWidth;

			// 30px is the left scroll button width;
			return (dateIndex === 0 ? 30 : 0) + hour + minute;
		}, [dateIndex, employee, date, schedulerState.schedulerResolution]);

		const snapPoints = React.useMemo<number[]>(() => {
			const diff = xWidth - startWidth - 30;
			const hours = diff / schedulerState.schedulerResolution.oneHourWidth;
			const snapWidth = diff / hours / 4;

			const points: number[] = [];
			if (diff > 0) {
				let startX = startWidth;
				while (startX <= xWidth) {
					points.push(startX);

					startX += snapWidth;
				}
			}

			return points;
		}, [startWidth, xWidth, schedulerState.schedulerResolution.mode]);

		const width = React.useMemo<number>(() => {
			if (!workOrder) {
				return 0;
			}

			const dates = workOrder.startAt.split(',');

			const momentIndexDate = moment.utc(date, 'YYYY-MM-DD');
			const findIndex = dates.findIndex((element) => element.includes(momentIndexDate.format('YYYY-MM-DD')));

			if (dates.length === 2 || findIndex === 0) {
				setDisableDragging(false);
			} else {
				setDisableDragging(true);
			}

			if (dates.length > 2 && findIndex !== -1 && dates.length - 1 > findIndex + 1) {
				setHasNext(true);
			} else {
				setHasNext(false);
			}

			if (findIndex !== -1 && dates.length - 1 >= findIndex + 1) {
				const startAt = moment.utc(dates[findIndex]);
				const endAt = moment.utc(dates[findIndex + 1]);

				const diff = Math.abs(endAt.diff(startAt, 'minutes'));

				return diff * schedulerState.schedulerResolution.oneMinuteWidth;
			}

			return schedulerState.schedulerResolution.oneHourWidth;
		}, [workOrder, date, schedulerState.schedulerResolution]);

		const getColor = React.useMemo<string>(() => {
			if (!workOrder) {
				return '#AFCDFB';
			}

			const findOrder = ordersState.list.find(
				(element) => parseInt(element.id, 10) === parseInt(workOrder.orderId!, 10)
			);
			if (findOrder && findOrder.phase) {
				return findOrder.phase.color;
			}

			return '#AFCDFB';
		}, [orderPhasesState.updatedAt, ordersState.updatedAt, workOrder]);

		const getOrderNo = React.useMemo<string>(() => {
			if (!workOrder) {
				return '';
			}

			const findOrder = ordersState.list.find(
				(element) => parseInt(element.id, 10) === parseInt(workOrder.orderId!, 10)
			);
			if (findOrder) {
				return findOrder.orderNo;
			}

			return workOrder.orderId;
		}, [ordersState.updatedAt, workOrder]);

		return (
			<>
				{schedulerState.isDragging &&
					snapPoints.map((element, index) => (
						<div
							key={index}
							style={{
								position: 'absolute',
								top: 0,
								left: (dateIndex === 0 ? 30 : 0) + element - 1,
								width: 1,
								height: 36,
								backgroundColor: rowIndex % 2 === 0 ? '#F2F2F2' : '#E6E6E6',
							}}
						></div>
					))}
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: dateIndex === 0 ? 30 : 0,
						width: startWidth,
						height: 36,
						backgroundColor: rowIndex % 2 === 0 ? '#f3e8e8' : '#e7e0e3',
					}}
				></div>
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: xWidth,
						width:
							schedulerState.schedulerResolution.mode === 'days'
								? schedulerState.schedulerResolution.boxWidth - xWidth + (dateIndex === 0 ? 30 : 0)
								: schedulerState.schedulerResolution.boxWidth *
										schedulerState.schedulerResolution.boxCount -
								  xWidth +
								  (dateIndex === 0 ? 30 : 0),
						height: 36,
						backgroundColor: rowIndex % 2 === 0 ? '#f3e8e8' : '#e7e0e3',
					}}
				></div>
				{workOrder && (
					<Rnd
						ref={ref}
						style={{
							zIndex: 1,
							backgroundColor: getColor,
							border: '.5px solid #6796ee',
						}}
						onDragStart={() => {
							dispatch(schedulerActions.setIsDragging(true));
						}}
						onDragStop={(e: Event, data: DraggableData) => {
							dispatch(schedulerActions.setIsDragging(false));

							if (onStop) {
								return onStop(e, data);
							}
						}}
						dragAxis="both"
						default={{
							x,
							y: 6,
							width,
							height: 24,
						}}
						position={{
							x,
							y: 6,
						}}
						size={{
							width,
							height: 24,
						}}
						// minWidth={schedulerState.schedulerResolution.oneHourWidth}
						disableDragging={disableDragging || schedulerState.schedulerResolution.mode === 'days'}
						enableResizing={{
							bottom: false,
							bottomLeft: false,
							bottomRight: false,
							left: false,
							right: false,
							top: false,
							topLeft: false,
							topRight: false,
						}}
						// bounds="parent"
					>
						{/* <Box ref={ref} {...restProps}> */}
						<div
							style={{
								width: '100%',
								height: '100%',
								display: 'flex',
								alignItems: 'center',
								transform: uiState.pulseOrderId == workOrder.orderId ? 'scale(1)' : undefined,
								animation: uiState.pulseOrderId == workOrder.orderId ? 'pulse 2s infinite' : undefined,
							}}
						>
							<Button
								component={'div'}
								id={`workorder-${employee.id}-${workOrder.id}`}
								className="handle items-center flex mx-1"
								size="small"
								// onMouseDown={handleMouseDown}
								onMouseOver={() => {
									if (uiState.pulseOrderId === workOrder.orderId) {
										dispatch(uiActions.pulseOrderId(undefined));
									}
								}}
								onClick={(e: any) => {
									if (schedulerState.isDragging) {
										return;
									}

									handleClick(e);
								}}
								color="inherit"
								sx={{
									width: '100%',
									height: '100%',
									alignItems: 'center',
									// minWidth:
									// 	schedulerState.schedulerResolution.mode === 'days' ? 'initial' : undefined,
									padding: schedulerState.schedulerResolution.mode === 'days' ? '0' : undefined,
								}}
							>
								{schedulerState.schedulerResolution.mode !== 'days' && (
									<div style={{ flex: 1 }} className="w-full flex">
										<Box style={{ width: 'calc(100% - 15px)' }} flexDirection={'row'} flexGrow={1}>
											<Typography
												style={{
													flex: 1,
													textAlign: 'center',
													fontSize: '.6rem',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													overflow: 'hidden',
													textTransform: 'none',
													color: invertColor(getColor),
												}}
												className="workorder-title"
											>
												<span style={{ fontWeight: 'bold' }}>#{getOrderNo}</span>
											</Typography>
										</Box>
										{hasNext && (
											<div style={{ marginLeft: 'auto' }} className="flex">
												<IcNext style={{ color: invertColor(getColor) }} />
											</div>
										)}
									</div>
								)}
							</Button>
							{schedulerState.schedulerResolution.mode !== 'days' && (
								<DeleteButton sx={{ width: '30px' }} onClick={handleDelete}>
									<IcRemove style={{ color: invertColor(getColor) }} />
								</DeleteButton>
							)}
						</div>

						{/* </Box> */}
					</Rnd>
				)}
			</>
		);
	}
)(({}) => ({
	position: 'absolute',
	overflow: 'hidden',
	display: 'flex',
	justifyContent: 'space-between',
	textTransform: 'none',
	color: '#ffffff',
	backgroundColor: 'red',
	'& > .MuiButton-root': {
		flexGrow: 1,
		minWidth: 0,
		textTransform: 'none',

		padding: '0 1-px',
		color: '#ffffff',
		backgroundColor: '0859A4',
		borderRadius: 0,
		fontSize: 10,
		'&:hover': {
			backgroundColor: '#389984',
		},
		'& > .workorder-title': {
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
		'& > .workorder-id': {
			fontWeight: 600,
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
}));

export const StickyBox = styled('div')({
	position: 'sticky',
	top: 0,
	left: 0,
	marginRight: '-100%',
	width: '100%',
	// height: '100%',
	border: 'inherit',
	zIndex: 1,
	pointerEvents: 'none',
	// float: 'left',
});

export const StickyButton = styled(Button)(({ position }: ButtonProps & { position: 'right' | 'left' }) => ({
	position: 'absolute',
	height: '100%',
	right: position === 'right' ? 0 : 'auto',
	left: position === 'left' ? 200 : 'auto',
	top: 0,
	minWidth: 30,
	width: 30,
	backgroundColor: '#e6eef6',
	border: '0.5px solid #dddfe2',
	boxSizing: 'border-box',
	'&:hover': {
		backgroundColor: '#e6eee6',
	},
	pointerEvents: 'all',
	borderRadius: 0,
}));

const ScheduleBox = styled('div')({
	// overflowX: 'scroll',
	// overflowY: 'auto',
	// position: 'relative',
	// minWidth: 800,
	// scrollBehavior: 'smooth',
	// '&::-webkit-scrollbar': {
	// 	width: 30,
	// 	height: 30,
	// },
	// '&::-webkit-scrollbar-corner, &::-webkit-scrollbar-track': {
	// 	backgroundColor: '#ffffff',
	// 	border: '0.5px solid #dddfe2',
	// },
	// '&::-webkit-scrollbar-thumb': {
	// 	backgroundColor: '#afcdfb',
	// 	'&:hover': {
	// 		backgroundColor: '#81a4d8',
	// 	},
	// 	'&:active': {
	// 		backgroundColor: '#5f83b8',
	// 	},
	// },
	// '&::-webkit-scrollbar-button:single-button': {
	// 	backgroundColor: '#ffffff',
	// 	backgroundSize: 'cover',
	// 	height: 30,
	// 	width: 30,
	// 	border: '0.5px solid #dddfe2',
	// 	'&:hover': {
	// 		backgroundColor: '#81a4d8',
	// 	},
	// 	'&:active': {
	// 		backgroundColor: '#5f83b8',
	// 	},
	// 	'&:disabled': {
	// 		backgroundColor: '#ced4da',
	// 	},
	// },
	// '&::-webkit-scrollbar-button:single-button:vertical:decrement': {
	// 	backgroundImage:
	// 		"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(8, 89, 164)'><polygon points='50,40 15,60 25,60 50,50 75,60 85,60'/></svg>\")",
	// 	'&:disabled': {
	// 		backgroundImage:
	// 			"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(130, 141, 158)'><polygon points='50,40 15,60 25,60 50,50 75,60 85,60'/></svg>\")",
	// 	},
	// },
	// '&::-webkit-scrollbar-button:single-button:vertical:increment': {
	// 	backgroundImage:
	// 		"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(8, 89, 164)'><polygon points='50,60 15,40 25,40 50,50 75,40 85,40'/></svg>\")",
	// 	'&:disabled': {
	// 		backgroundImage:
	// 			"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(130, 141, 158)'><polygon points='50,60 15,40 25,40 50,50 75,40 85,40'/></svg>\")",
	// 	},
	// },
	// '&::-webkit-scrollbar-button:single-button:horizontal:decrement': {
	// 	backgroundImage:
	// 		"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(8, 89, 164)'><polygon points='40,50 60,15 60,25 50,50 60,75 60,85'/></svg>\")",
	// 	'&:disabled': {
	// 		backgroundImage:
	// 			"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(130, 141, 158)'><polygon points='40,50 60,15 60,25 50,50 60,75 60,85'/></svg>\")",
	// 	},
	// },
	// '&::-webkit-scrollbar-button:single-button:horizontal:increment': {
	// 	backgroundImage:
	// 		"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(8, 89, 164)'><polygon points='60,50 40,15 40,25 50,50 40,75 40,85'/></svg>\")",
	// 	'&:disabled': {
	// 		backgroundImage:
	// 			"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(130, 141, 158)'><polygon points='60,50 40,15 40,25 50,50 40,75 40,85'/></svg>\")",
	// 	},
	// },
});

export default ScheduleBox;
