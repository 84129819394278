import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import API from '../../lib/utils/API';
import AAdmin from '../../models/Admin/AAdmin';
import AdminCreateRequest from '../../network/requests/Admin/AdminCreateRequest';
import AdminDeleteRequest from '../../network/requests/Admin/AdminDeleteRequest';
import AdminUpdateRequest from '../../network/requests/Admin/AdminUpdateRequest';
import OkResponse from '../../network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('aAdmins/index', async (_, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, AxiosResponse<AAdmin[]>>('/admin/admins');

		thunkApi.dispatch(requestActions.beforeFulfilled(index.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(index.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: {}, error });
	}
});

const store = createAsyncThunk('aAdmins/store', async (payload: AdminCreateRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(store.typePrefix));
	try {
		const response = await API.post<AdminCreateRequest, AAdmin>('/admin/admins', payload);

		thunkApi.dispatch(requestActions.beforeFulfilled(store.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(store.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const update = createAsyncThunk('aAdmins/update', async ({ id, ...payload }: AdminUpdateRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	try {
		const response = await API.put<Omit<AdminUpdateRequest, 'id'>, AxiosResponse<AAdmin>>(
			`/admin/admins/${id}`,
			payload
		);

		thunkApi.dispatch(requestActions.beforeFulfilled(update.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(update.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const destroy = createAsyncThunk('aAdmins/destroy', async (payload: AdminDeleteRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(destroy.typePrefix));
	try {
		await API.delete<AdminDeleteRequest, AxiosResponse<OkResponse>>(`/admin/admins/${payload.id}`);

		thunkApi.dispatch(requestActions.beforeFulfilled(destroy.typePrefix));
		return payload;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(destroy.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const aAdminsAsyncActions = {
	index,
	store,
	update,
	destroy,
};

export default aAdminsAsyncActions;
