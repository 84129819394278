import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import API from '../../lib/utils/API';
import ASyncUser from '../../models/Admin/ASyncUser';
import SyncUserBlockRequest from '../../network/requests/Admin/SyncUserBlockRequest';
import SyncUserCheckPermissionsRequest from '../../network/requests/Admin/SyncUserCheckPermissionsRequest';
import SyncUserCreateRequest from '../../network/requests/Admin/SyncUserCreateRequest';
import SyncUserDeleteRequest from '../../network/requests/Admin/SyncUserDeleteRequest';
import SyncUserSyncRequest from '../../network/requests/Admin/SyncUserSyncRequest';
import SyncUserUpdateRequest from '../../network/requests/Admin/SyncUserUpdateRequest';
import CheckPermissionsResponse from '../../network/responses/CheckPermissionsResponse';
import OkResponse from '../../network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('aSyncUsers/index', async (_, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, AxiosResponse<ASyncUser[]>>('/admin/sync-users');

		thunkApi.dispatch(requestActions.beforeFulfilled(index.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(index.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: {}, error });
	}
});

const store = createAsyncThunk('aSyncUsers/store', async (payload: SyncUserCreateRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(store.typePrefix));
	try {
		const response = await API.post<SyncUserCreateRequest, ASyncUser>('/admin/sync-users', payload);

		thunkApi.dispatch(requestActions.beforeFulfilled(store.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(store.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const update = createAsyncThunk('aSyncUsers/update', async ({ id, ...payload }: SyncUserUpdateRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	try {
		const response = await API.put<Omit<SyncUserUpdateRequest, 'id'>, AxiosResponse<ASyncUser>>(
			`/admin/sync-users/${id}`,
			payload
		);

		thunkApi.dispatch(requestActions.beforeFulfilled(update.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(update.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const block = createAsyncThunk('aSyncUsers/block', async (payload: SyncUserBlockRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(block.typePrefix));
	try {
		const response = await API.get<SyncUserBlockRequest, AxiosResponse<ASyncUser>>(
			`/admin/sync-users/${payload.id}`
		);

		thunkApi.dispatch(requestActions.beforeFulfilled(block.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(block.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const destroy = createAsyncThunk('aSyncUsers/destroy', async (payload: SyncUserDeleteRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(destroy.typePrefix));
	try {
		await API.delete<SyncUserDeleteRequest, AxiosResponse<OkResponse>>(`/admin/sync-users/${payload.id}`);

		thunkApi.dispatch(requestActions.beforeFulfilled(destroy.typePrefix));
		return payload;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(destroy.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const checkPermissions = createAsyncThunk(
	'aSyncUsers/check-permissions',
	async (payload: SyncUserCheckPermissionsRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(checkPermissions.typePrefix));
		try {
			const response = await API.get<SyncUserCheckPermissionsRequest, AxiosResponse<CheckPermissionsResponse>>(
				`/admin/sync-users/${payload.id}/check-permissions`
			);

			thunkApi.dispatch(requestActions.beforeFulfilled(checkPermissions.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(checkPermissions.typePrefix));
			console.log(error);
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const sync = createAsyncThunk('aSyncUsers/sync', async (payload: SyncUserSyncRequest, thunkApi) => {
	thunkApi.dispatch(
		requestActions.startedWithPayload({
			name: sync.typePrefix,
			message: '',
			payload: { payload: { userId: payload.id } },
		})
	);
	try {
		const response = await API.get<SyncUserSyncRequest, AxiosResponse<CheckPermissionsResponse>>(
			`/admin/sync-users/${payload.id}/sync`
		);

		thunkApi.dispatch(requestActions.beforeFulfilled(sync.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(sync.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const aSyncUsersAsyncActions = {
	index,
	store,
	update,
	block,
	destroy,
	checkPermissions,
	sync,
};

export default aSyncUsersAsyncActions;
