import { Box, Button, Dialog, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import images from '../../../res/images';

interface Props {
	open: boolean;
	onClose: () => void;
	message?: string | null;
	refreshFn?: () => void;
}

const useWorkDialogStyles = makeStyles({
	root: {
		'& .MuiPaper-root': {
			backgroundColor: '#f1f1f1',
		},
	},
});

const NotAcceptedModal: React.FC<Props> = ({ open, onClose, message }) => {
	const classes = useWorkDialogStyles();

	return (
		<Dialog className={classes.root} open={open} fullWidth maxWidth="xs">
			<Box paddingTop="3em" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
				<img width="100%" src={images.default.ilNotaccepted} />
				<Box
					rowGap="1em"
					paddingY="2em"
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					paddingX="2em"
				>
					{/* <Typography textAlign="center" sx={{ color: '#0859a4', fontWeight: 'bolder' }} variant="h5">
						403 Error
					</Typography>
					<Typography textAlign="center" sx={{ color: '#000000', fontWeight: 'bolder' }} variant="h6">
						You can’t access this resource
					</Typography> */}
					<Typography
						sx={{ fontSize: '1rem', color: '#0859a4', fontWeight: 'bold' }}
						paddingX="3em"
						textAlign="center"
					>
						{message || 'We are sorry but you do not have permission to access this web resource.'}
					</Typography>
					<Box display="flex" alignItems="center" justifyContent="center">
						<Button onClick={onClose} size="small" variant="contained">
							Go back
						</Button>
					</Box>
				</Box>
			</Box>
		</Dialog>
	);
};

export default NotAcceptedModal;
