import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	Divider,
	IconButton,
	MenuItem,
	Select,
	Tab,
	Tabs,
	TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography } from 'lib/components';
import { WidgetType } from 'lib/utils/enums';
import Employee, { Hours, Udf } from 'models/Employee';
import moment from 'moment';
import React from 'react';
import { IcDialogClose } from 'res/icons';
import { useAppDispatch } from 'store';
import employeeAsyncActions from 'store/actions/employees.action';

interface EditDialogProps {
	open: boolean;
	schedule: Employee;
	onClose: () => void;
}

const useEditDialogStyles = makeStyles({
	root: {},
	header: {
		padding: '15px 60px 15px 10px',
		position: 'relative',
		'& > .icon-buttons': {
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			right: 5,
			padding: 0,
			display: 'flex',
			'& > .MuiIconButton-root': {
				height: 20,
				width: 20,
				padding: 0,
				marginRight: 5,
			},
		},
	},
	body: {
		paddingTop: 15,
		padding: '15px 10px',
		borderBottom: '0.5px solid #dddfe2',
		'& > .form-group': {
			display: 'flex',
			alignItems: 'center',
			marginBottom: 10,
			'& > .form-label': {
				flexBasis: '34%',
				color: '#0859a4',
				fontWeight: 600,
			},
			'& > .form-input': {
				fontSize: 12,
				flexBasis: '66%',
				borderRadius: 0,
				'& > .MuiList-root': {
					borderRadius: 0,
					fontSize: 12,
				},
				'& > .MuiInputBase-root': {
					borderRadius: 0,
					fontSize: 12,
				},
			},
		},
	},
	select: {
		borderRadius: 0,
		fontSize: 12,
		'& li': {
			fontSize: 12,
		},
	},
	footer: {
		padding: '15px 10px',
		'& .warning': {
			marginLeft: 10,
			color: '#fa2e2e',
		},
		'& > .button': {
			margin: '10px 10px 0 0',
			borderRadius: 0,
			color: '#0859a4',
			textTransform: 'none',
			'&:hover': {
				backgroundColor: '#0859a4',
				color: '#ffffff',
			},
		},
	},
});

const EditDialog = ({ onClose, open, schedule }: EditDialogProps) => {
	const dispatch = useAppDispatch();

	const classes = useEditDialogStyles();

	const [value, setValue] = React.useState(0);

	const [username, setUsername] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');
	const [newPassword, setNewPassword] = React.useState<string>('');
	const [newPasswordConfirmation, setNewPasswordConfirmation] = React.useState<string>('');
	const [address, setAddress] = React.useState<string>('');
	const [employeeNo, setEmployeeNo] = React.useState<string>('');
	const [name, setName] = React.useState<string>('');
	const [jobTitle, setJobTitle] = React.useState<string | null>(null);
	const [udfs, setUDFS] = React.useState<Array<Udf>>([]);
	const [hours, setHours] = React.useState<Hours>({
		monFrom: '09:00',
		monTo: '18:00',
		tueFrom: '09:00',
		tueTo: '18:00',
		wedFrom: '09:00',
		wedTo: '18:00',
		thuFrom: '09:00',
		thuTo: '18:00',
		friFrom: '09:00',
		friTo: '18:00',
		satFrom: '09:00',
		satTo: '18:00',
		sunFrom: '09:00',
		sunTo: '18:00',
	});

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleSubmit = React.useCallback(() => {
		dispatch(
			employeeAsyncActions.update({
				id: schedule.id,
				employeeNo,
				username,
				email,
				address,
				newPassword,
				newPasswordConfirmation,
				name,
				jobTitle,
				udfs: udfs.map<Omit<Udf, 'shape'>>(({ id, name, value }) => ({ id, name, value })),
				hours,
			})
		);
	}, [
		dispatch,
		username,
		email,
		address,
		newPassword,
		newPasswordConfirmation,
		employeeNo,
		name,
		jobTitle,
		udfs,
		hours,
	]);

	const setUDFValue = React.useCallback(
		(element: Udf, value: string | null) => {
			const findIndex = udfs.findIndex((e) => e.id === element.id);
			if (findIndex !== -1) {
				const newUDFValues = [...udfs];
				newUDFValues.splice(findIndex, 1, {
					...element,
					value,
				});
				setUDFS(newUDFValues);
			}
		},
		[udfs]
	);

	const setTime = React.useCallback(
		(key: keyof Hours, value: string) => {
			if (!hours) {
				return;
			}

			const newHours = { ...hours };
			newHours[key] = value;

			setHours(newHours);
		},
		[hours]
	);

	React.useEffect(() => {
		if (!open) {
			return;
		}

		setEmployeeNo(schedule.employeeNo);
		setName(schedule.name);
		setUsername(schedule.username ?? '');
		setEmail(schedule.email ?? '');
		setAddress(schedule.address ?? '');
		setJobTitle(schedule.jobTitle);
		setUDFS(schedule.udfs);
		setHours(schedule.hours);
	}, [open]);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Dialog className={classes.root} hideBackdrop open={open} fullWidth maxWidth="sm">
				<Box className={classes.header}>
					<Tabs value={value} className="tabs" onChange={handleChange} centered>
						<Tab label="Information" />
						<Tab label="Application" />
					</Tabs>
					<Box className="icon-buttons">
						<IconButton onClick={onClose}>
							<IcDialogClose />
						</IconButton>
					</Box>
				</Box>
				{value === 0 && (
					<Box className={classes.body}>
						<Box className="form-group">
							<Typography variant="p" className="form-label">
								Employee No:
							</Typography>
							<TextField
								className="form-input"
								value={employeeNo}
								onChange={(e) => setEmployeeNo(e.target.value)}
								size="small"
							/>
						</Box>
						<Box className="form-group">
							<Typography variant="p" className="form-label">
								Name:
							</Typography>
							<TextField
								className="form-input"
								value={name}
								onChange={(e) => setName(e.target.value)}
								size="small"
							/>
						</Box>
						<Box className="form-group">
							<Typography variant="p" className="form-label">
								Job Title:
							</Typography>
							<TextField
								className="form-input"
								value={jobTitle ?? ''}
								onChange={(e) => setJobTitle(e.target.value)}
								size="small"
							/>
						</Box>
						{udfs.map((element) => (
							<Box key={element.id} className="form-group">
								<Typography variant="p" className="form-label">
									{element.shape.label}:
								</Typography>
								{element.shape.widgetType === WidgetType.DropDown ? (
									<Select
										className="form-input"
										value={element.value}
										onChange={(e) => setUDFValue(element, e.target.value as string)}
										size="small"
									>
										{element.shape.items?.map((item, index) => (
											<MenuItem value={item} key={index}>
												{item}
											</MenuItem>
										))}
									</Select>
								) : element.shape.widgetType === WidgetType.DateEdit ? (
									<DesktopDatePicker
										inputFormat="MM/dd/yyyy"
										value={element.value}
										onChange={(value) =>
											setUDFValue(element, value ? moment.utc(value).format('YYYY-MM-DD') : null)
										}
										renderInput={(params) => <TextField size="small" {...params} />}
									/>
								) : element.shape.widgetType === WidgetType.Checkbox ? (
									<Checkbox
										// className="form-input"
										value={element.value}
										checked={element.value === '1'}
										onChange={(e) => setUDFValue(element, e.target.checked ? '1' : '0')}
										size="medium"
									/>
								) : (
									<TextField
										className="form-input"
										value={element.value}
										onChange={(e) => setUDFValue(element, e.target.value)}
										size="small"
									/>
								)}
							</Box>
						))}
						{hours !== undefined && (
							<Box marginTop="2em" className="form-group">
								<Typography variant="p" className="form-label">
									WORKER WEEKLY SCHEDULES
								</Typography>
								<Box width="100%">
									<Box paddingX="4em" width="100%" display="flex" justifyContent="space-evenly">
										<Typography
											style={{ flex: '1', textAlign: 'start', color: '#0859a4' }}
											variant="subheading1"
											className="form-label"
										>
											Day
										</Typography>
										<Typography
											style={{ flex: '1', color: '#0859a4' }}
											variant="subheading1"
											className="form-label"
										>
											Start Time
										</Typography>
										<Box width={16} />
										<Typography
											style={{ flex: '1', color: '#0859a4' }}
											variant="subheading1"
											className="form-label"
										>
											End Time
										</Typography>
									</Box>
									<Box
										paddingX="4em"
										marginTop="1em"
										width="100%"
										display="flex"
										justifyContent="space-evenly"
									>
										<Typography style={{ flex: '1' }} variant="p" className="form-label">
											Monday
										</Typography>
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.monFrom}
											onChange={(e) => setTime('monFrom', e.target.value)}
											type="text"
										/>
										<Box width={16} />
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.monTo}
											onChange={(e) => setTime('monTo', e.target.value)}
											type="text"
										/>
									</Box>
									<Box
										paddingX="4em"
										marginTop="1em"
										width="100%"
										display="flex"
										justifyContent="space-evenly"
									>
										<Typography style={{ flex: '1' }} variant="p" className="form-label">
											Tuesday
										</Typography>
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.tueFrom}
											onChange={(e) => setTime('tueFrom', e.target.value)}
											type="text"
										/>
										<Box width={16} />
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.tueTo}
											onChange={(e) => setTime('tueTo', e.target.value)}
											type="text"
										/>
									</Box>
									<Box
										paddingX="4em"
										marginTop="1em"
										width="100%"
										display="flex"
										justifyContent="space-evenly"
									>
										<Typography style={{ flex: '1' }} variant="p" className="form-label">
											Wednesday
										</Typography>
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.wedFrom}
											onChange={(e) => setTime('wedFrom', e.target.value)}
											type="text"
										/>
										<Box width={16} />
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.wedTo}
											onChange={(e) => setTime('wedTo', e.target.value)}
											type="text"
										/>
									</Box>
									<Box
										paddingX="4em"
										marginTop="1em"
										width="100%"
										display="flex"
										justifyContent="space-evenly"
									>
										<Typography style={{ flex: '1' }} variant="p" className="form-label">
											Thursday
										</Typography>
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.thuFrom}
											onChange={(e) => setTime('thuFrom', e.target.value)}
											type="text"
										/>
										<Box width={16} />
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.thuTo}
											onChange={(e) => setTime('thuTo', e.target.value)}
											type="text"
										/>
									</Box>
									<Box
										paddingX="4em"
										marginTop="1em"
										width="100%"
										display="flex"
										justifyContent="space-evenly"
									>
										<Typography style={{ flex: '1' }} variant="p" className="form-label">
											Friday
										</Typography>
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.friFrom}
											onChange={(e) => setTime('friFrom', e.target.value)}
											type="text"
										/>
										<Box width={16} />
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.friTo}
											onChange={(e) => setTime('friTo', e.target.value)}
											type="text"
										/>
									</Box>
									<Box
										paddingX="4em"
										marginTop="1em"
										width="100%"
										display="flex"
										justifyContent="space-evenly"
									>
										<Typography style={{ flex: '1' }} variant="p" className="form-label">
											Saturday
										</Typography>
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.satFrom}
											onChange={(e) => setTime('satFrom', e.target.value)}
											type="text"
										/>
										<Box width={16} />
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.satTo}
											onChange={(e) => setTime('satTo', e.target.value)}
											type="text"
										/>
									</Box>
									<Box
										paddingX="4em"
										marginTop="1em"
										width="100%"
										display="flex"
										justifyContent="space-evenly"
									>
										<Typography style={{ flex: '1' }} variant="p" className="form-label">
											Sunday
										</Typography>
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.sunFrom}
											onChange={(e) => setTime('sunFrom', e.target.value)}
											type="text"
										/>
										<Box width={16} />
										<TextField
											style={{ flex: '1' }}
											size="small"
											value={hours.sunTo}
											onChange={(e) => setTime('sunTo', e.target.value)}
											type="text"
										/>
									</Box>
								</Box>
							</Box>
						)}
					</Box>
				)}
				{value === 1 && (
					<Box className={classes.body}>
						<Box className="form-group">
							<Typography variant="p" className="form-label">
								Username:
							</Typography>
							<TextField
								className="form-input"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								size="small"
							/>
						</Box>
						<Box className="form-group">
							<Typography variant="p" className="form-label">
								E-Mail:
							</Typography>
							<TextField
								className="form-input"
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								size="small"
							/>
						</Box>
						<Box className="form-group">
							<Typography variant="p" className="form-label">
								Address:
							</Typography>
							<TextField
								className="form-input"
								value={address}
								onChange={(e) => setAddress(e.target.value)}
								size="small"
							/>
						</Box>
						<Divider style={{ marginTop: 8, marginBottom: 12 }} />
						<Box className="form-group">
							<Typography variant="p" className="form-label">
								New Password:
							</Typography>
							<TextField
								className="form-input"
								type="password"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								size="small"
							/>
						</Box>
						<Box className="form-group">
							<Typography variant="p" className="form-label">
								Confirm New Password:
							</Typography>
							<TextField
								className="form-input"
								type="password"
								value={newPasswordConfirmation}
								onChange={(e) => setNewPasswordConfirmation(e.target.value)}
								size="small"
							/>
						</Box>
					</Box>
				)}
				<Box className={classes.footer}>
					<Button
						color="primary"
						className="button"
						variant="outlined"
						onClick={() => {
							handleSubmit();
							onClose();
						}}
					>
						Save and Close
					</Button>
					<Button className="button" variant="outlined" onClick={onClose}>
						Cancel
					</Button>
				</Box>
			</Dialog>
		</LocalizationProvider>
	);
};

export default EditDialog;
