import { Alert, Button, Dialog, FormControl, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Image } from 'lib/components';
import SignInRequest from 'network/requests/SignInRequest';
import { useAppDispatch } from 'store';
import { LoadingButton } from '@mui/lab';
import authenticationAsyncActions from 'store/actions/authentication.action';
import { resetAuthState } from 'store/slices/authentication.slice';
import { useAuthenticationState } from 'store/selectors';
import { LoadingState } from 'lib/utils/enums';
// import { useHistory } from 'react-router';

interface Props {
	isOpen: boolean;
	onClose: () => void;
}

const useStyle = makeStyles({
	root: {},
	header: {
		marginTop: '1em',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		rowGap: '1em',
		alignItems: 'center',
	},
	form: {
		marginTop: '2em',
		width: '100%',
		padding: '0 1em',
	},
	fromControl: {
		width: '100%',
		display: 'flex',
		marginTop: '1em',
	},
	submitButton: {
		marginTop: '1em',
		height: 50,
	},
});

const SignInModal: React.FC<Props> = ({ isOpen, onClose }) => {
	const classes = useStyle();
	const dispatch = useAppDispatch();
	const authentication = useAuthenticationState();
	const formik = useFormik<SignInRequest>({
		initialValues: {
			code: '',
			username: '',
			password: '',
		},
		onSubmit: ({ code, password, username }) => {
			dispatch(authenticationAsyncActions.signIn({ code, password, username }));
		},
	});

	useEffect(() => {
		dispatch(resetAuthState());
	}, [dispatch]);

	return (
		<Dialog
			sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			open={isOpen}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<Box minWidth="400px" minHeight="600px">
				<Box className={classes.header}>
					<Box sx={{ display: 'flex', width: '100%' }}>
						<Button onClick={() => onClose()} sx={{ marginLeft: 'auto' }}>
							<Image src="icDialogClose" />
						</Button>
					</Box>

					<Image style={{ width: '40%' }} src="icSpire" />
					<Typography sx={{ color: '#0859a4' }} variant="h4">
						Sign in
					</Typography>
					<Typography>Sign in with spire your credentials</Typography>
				</Box>

				<form onSubmit={formik.handleSubmit} className={classes.form}>
					{authentication.status === LoadingState.FAILED && (
						<Alert hidden={true} severity="error">
							{authentication.errorMessage}
						</Alert>
					)}

					<FormControl className={classes.fromControl}>
						<Typography>Code</Typography>
						<TextField value={formik.values.code} onChange={formik.handleChange} name="code" type="text" />
					</FormControl>
					<FormControl className={classes.fromControl}>
						<Typography>Username</Typography>
						<TextField
							value={formik.values.username}
							onChange={formik.handleChange}
							name="username"
							type="text"
						/>
					</FormControl>
					<FormControl className={classes.fromControl}>
						<Typography>Password</Typography>
						<TextField
							value={formik.values.password}
							onChange={formik.handleChange}
							name="password"
							type="password"
						/>
					</FormControl>
					<LoadingButton
						loading={authentication.status === LoadingState.PENDING}
						fullWidth
						color="primary"
						className={classes.submitButton}
						variant="outlined"
						type="submit"
					>
						Sign in
					</LoadingButton>
				</form>
			</Box>
		</Dialog>
	);
};

export default SignInModal;
