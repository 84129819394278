import { TabContext, TabPanel } from '@mui/lab';
import TabList from '@mui/lab/TabList';
import { Button, MenuItem, Select, Tab } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { Box } from '@mui/system';
import AppHeader from 'lib/components/AppHeader';
import { Breadcrumbs } from 'lib/components/Common';
import LogoutModal from 'lib/components/LogoutModal';
import ColorPicker from 'lib/components/ScheduleBoard/Scheduler/WorkDialog/ColorPicker';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IcHome } from 'res/icons';
import { Typography } from '../../lib/components';
import { OrderPhase } from '../../models/Order';
import { useAppDispatch } from '../../store';
import orderPhasesAsyncActions from '../../store/actions/orderPhases.action';
import { useCompaniesState, useOrderPhasesState } from '../../store/selectors';
import { orderPhasesActions } from '../../store/slices/orderPhases.slice';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
const CustomSelect = styled(Select)({
	fontSize: 12,
	borderRadius: 0,
	backgroundColor: '#ffffff',
	'& > .MuiSelect-select': {
		display: 'flex',
		alignItems: 'center',
	},
});

const ColorBox = ({ color }: { color: string }) => {
	return (
		<Box
			width={10}
			minWidth={10}
			height={10}
			mr="5px"
			sx={{ backgroundColor: color }}
			border="1px solid #000000"
		></Box>
	);
};

const useScheduleBoardStyles = makeStyles({
	root: {
		height: '100vh',
		overflowY: 'hidden',
	},
	breadcrumbs: {
		paddingLeft: '2em',
		height: '50px',
	},
	select: {
		borderRadius: 0,
		fontSize: 12,
		'& li': {
			fontSize: 12,
		},
	},
	menuItem: {
		borderBottom: '0.5px solid #dddfe2',
	},
});
const ScheduleBoardSettings = () => {
	const dispatch = useAppDispatch();

	const classes = useScheduleBoardStyles();
	const params = useParams<{
		companyName: string;
	}>();

	const companiesState = useCompaniesState();
	const orderPhasesState = useOrderPhasesState();

	const [tabs] = React.useState<Array<Pick<OrderPhase, 'description' | 'phaseType'>>>([
		{
			description: 'Sales Orders',
			phaseType: 'SORD',
		},
		{
			description: 'Purchase Orders',
			phaseType: 'PORD',
		},
		{
			description: 'Production Orders',
			phaseType: 'BORD',
		},
		{
			description: 'Sales History',
			phaseType: 'SHIS',
		},
		{
			description: 'Purchase History',
			phaseType: 'PHIS',
		},
		{
			description: 'Production History',
			phaseType: 'BHIS',
		},
		{
			description: 'Freight Verify Sync',
			phaseType: 'FHIS',
		},
	]);

	const [tabIndex, setTabIndex] = useState('SORD');
	const handleChange = (_: React.SyntheticEvent, newValue: string) => {
		setTabIndex(newValue);
	};
	const [colorId, setColorId] = useState<string | null>(null);
	const [color, setColor] = useState<string | null>(null);
	// const [passwordss, setPasswordss] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const handlePasswordChange = (e: { target: { value: React.SetStateAction<string> } }) => {
		setPassword(e.target.value);
	};

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};
	const currentCompany = React.useMemo<string>(() => {
		const company = companiesState.list.find((element) => element.name === params.companyName);
		if (company && company.description) {
			return company.description;
		}

		return '';
	}, [params.companyName]);

	const phases = React.useMemo<Array<OrderPhase>>(() => {
		return orderPhasesState.list.filter((element) => element.phaseType === tabIndex);
	}, [orderPhasesState.list, tabIndex]);

	const defaultPhase = React.useMemo<OrderPhase | undefined>(() => {
		return orderPhasesState.list.find((element) => element.phaseType === tabIndex && element.isDefault);
	}, [orderPhasesState.list, tabIndex]);

	React.useEffect(() => {
		dispatch(orderPhasesAsyncActions.index(params.companyName));
	}, []);
	const [openLogoutModal, setOpenLogoutModal] = React.useState(false);

	const freightVerifySync = React.useCallback(() => {
		dispatch(orderPhasesAsyncActions.freightVerifySync({ companyName: params.companyName }));
	}, [params.companyName]);

	const [apiData, setApiData] = useState(null);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [url, setUrl] = useState('');

	const saveData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL!}/api/v1/freightVerify/${params.companyName}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('accessToken') as string),
					},

					body: JSON.stringify({
						username: username,
						password: password,
						url: url,
					}),
				}
			);

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const data = await response.json();

			if (data.error) return;

			setApiData(data);

			setUsername(data.username);
			setPassword(data.password);
			setUrl(data.url);
		} catch (error) {
			console.error('There was a problem fetching the data:', error);
		}
	};

	const getData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL!}/api/v1/freightVerify/${params.companyName}`,
				{
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('accessToken') as string),
					},
				}
			);

			const data = await response.json();

			console.log('data : ', data);

			if (data.error) return;
			console.log('data : ', JSON.stringify(data));
			setApiData(data);
			setUsername(data.username);
			setPassword(data.password);
			setUrl(data.url);
		} catch (error) {
			console.error('There was a problem while getting the data:', error);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const closeModal = () => {
		setOpenLogoutModal(false);
	};
	const openModal = () => {
		setOpenLogoutModal(true);
	};
	return (
		<Box className={classes.root}>
			{color !== null && (
				<ColorPicker
					setColor={(value: string) => {
						if (!colorId) {
							return;
						}

						dispatch(orderPhasesAsyncActions.update({ id: colorId, color: value }));
						setColorId(null);
						setColor(null);
					}}
					color={color}
					onClose={() => {
						setColorId(null);
						setColor(null);
					}}
					open={colorId !== null}
				/>
			)}
			<Box>
				<LogoutModal onclose={closeModal} open={openLogoutModal} />
				<AppHeader />
				<Box display="flex">
					<Breadcrumbs className={classes.breadcrumbs}>
						<Link className="nav-item" to="/">
							<IcHome />
						</Link>
						<Link className="nav-item" to="/selectcompany">
							{currentCompany}
						</Link>
						<Link className="nav-item" to={`/company/${params.companyName}/dashboard`}>
							Dashboard
						</Link>
						<Link className="nav-item" to={`/company/${params.companyName}/scheduleboard`}>
							Schedule Board
						</Link>
						<Link className="nav-item active" to={`/company/${params.companyName}/scheduleboard/settings`}>
							Settings
						</Link>
					</Breadcrumbs>
					<Button sx={{ marginLeft: 'auto' }} onClick={openModal} color="error">
						Sign out
					</Button>
				</Box>
			</Box>

			<Box display="flex" flexDirection={'column'} flexWrap="wrap">
				<TabContext value={tabIndex}>
					<Box width="100%" sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange} aria-label="lab API tabs example">
							{tabs.map((element) => (
								<Tab key={element.description} label={element.description} value={element.phaseType} />
							))}
						</TabList>
					</Box>

					{tabs.map((tab) => (
						<TabPanel key={tab.description} value={tab.phaseType}>
							<div
								style={{
									height: 'calc(100vh - (64px + 50px + 48px + 24px + 24px + 64px))',
									overflowY: 'scroll',
								}}
							>
								{tab.description === 'Freight Verify Sync' ? (
									<div>
										<div style={{ width: '100%', maxWidth: '600px', padding: '10px' }}>
											<div
												style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
											>
												<div style={{ width: '100px', marginRight: '10px' }}>
													<p>URL: </p>
												</div>
												<div
													style={{
														flexGrow: 1,
														border: '1px solid #ccc',
														borderRadius: '5px',
														padding: '5px 10px',
													}}
												>
													<input
														type="text"
														value={url}
														onChange={(e) => setUrl(e.target.value)}
														placeholder="URL"
														style={{ width: '100%' }}
													/>
												</div>
											</div>
											<div style={{ display: 'flex', marginBottom: '10px' }}>
												<div style={{ width: '100px', marginRight: '10px' }}>
													<p>Username: </p>
												</div>
												<div
													style={{
														flexGrow: 1,
														border: '1px solid #ccc',
														borderRadius: '5px',
														padding: '5px 10px',
													}}
												>
													<input
														type="text"
														value={username}
														onChange={(e) => setUsername(e.target.value)}
														placeholder="username"
														style={{ width: '100%' }}
													/>
												</div>
											</div>
											<div
												style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
											>
												<div
													style={{
														flexGrow: 1,
													}}
												>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															marginBottom: '15px',
														}}
													>
														<div style={{ width: '100px', marginRight: '10px' }}>
															<p>Password: </p>
														</div>
														<div
															style={{
																flexGrow: 1,
																position: 'relative',
																border: '1px solid #ccc',
																borderRadius: '5px',
																padding: '5px 10px',
															}}
														>
															<input
																type={showPassword ? 'text' : 'password'}
																value={password}
																placeholder="password"
																onChange={handlePasswordChange}
																style={{
																	width: 'calc(100% - 40px)',
																	paddingRight: '40px',
																}}
															/>
															<button
																onClick={togglePasswordVisibility}
																style={{
																	position: 'absolute',
																	top: '50%',
																	right: '10px',
																	transform: 'translateY(-50%)',
																	border: 'none',
																	background: 'none',
																	cursor: 'pointer',
																}}
															>
																{showPassword ? <FaEyeSlash /> : <FaEye />}
															</button>
														</div>
													</div>
												</div>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'end',
													marginTop: 40,
													gap: 8,
												}}
											>
												<Button variant="contained" onClick={saveData}>
													Save
												</Button>
												<Button variant="contained" onClick={() => freightVerifySync()}>
													Freight Verify Sync
												</Button>
											</div>
										</div>
									</div>
								) : (
									<Box columnGap="2em" alignItems="center" display="flex">
										<Box width={250}>
											<Typography>Default Phase</Typography>
										</Box>
										<Box width={300}>
											<CustomSelect
												className="form-input"
												size="small"
												name="orderStatus"
												value={defaultPhase?.id}
												sx={{ width: '100%' }}
												MenuProps={{
													classes: { paper: classes.select },
												}}
											>
												{phases.map((item) => (
													<MenuItem
														key={item.id}
														value={item.id}
														component={Button}
														className={classes.menuItem}
														fullWidth
														onClick={() => {
															dispatch(orderPhasesActions.setDefault({ id: item.id }));
															dispatch(
																orderPhasesAsyncActions.setDefault({
																	companyName: params.companyName,
																	id: item.id,
																})
															);
														}}
													>
														<ColorBox color={item.color} />
														{item.description}
													</MenuItem>
												))}
											</CustomSelect>
										</Box>
									</Box>
								)}
								{phases.map((element) => (
									<Box
										key={element.id.toString()}
										marginTop={'1em'}
										columnGap="2em"
										alignItems="center"
										display="flex"
									>
										<Box width={250}>
											<Typography>{element.description}</Typography>
										</Box>
										<Box width={300}>
											<Button
												onClick={() => {
													setColorId(element.id);
													setColor(element.color);
												}}
												sx={{ backgroundColor: element.color, width: '100%', color: 'white' }}
												variant="outlined"
											>
												{element.color}
											</Button>
										</Box>
									</Box>
								))}
							</div>
						</TabPanel>
					))}
				</TabContext>
				<Box display={'flex'} alignItems={'center'}>
					<Button
						component={Link}
						variant="contained"
						to={`/company/${params.companyName}/scheduleboard`}
						style={{ marginLeft: 24, width: 80 }}
					>
						Close
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default ScheduleBoardSettings;
