import {
	Box,
	Button,
	Divider,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { makeStyles } from '@mui/styles';
import { Typography } from 'lib/components';
import moment from 'moment';
import React from 'react';
import Order, { OrderItem } from '../../../../../models/Order';
import { useAppDispatch } from '../../../../../store';
import { useEmployeesState } from '../../../../../store/selectors';
import ordersAsyncActions from '../../../../../store/actions/orders.action';
import getUDFValue from '../../../../getUDFValue';
import AddCustomItemModal from '../../AddCustomItemModal';

const FormLabel = styled(Typography)({
	fontSize: 12,
	marginBottom: 5,
});

const CustomTextField = styled(TextField)({
	fontSize: 12,
	marginBottom: 5,
	'& > .MuiInputBase-root': {
		borderRadius: 0,
		fontSize: 12,
		'& > input': {
			backgroundColor: '#ffffff',
			'&:disabled': {
				backgroundColor: '#f1f1f1',
			},
		},
	},
});

const ContentLabel = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	padding: '15px 0',
	color: '#0859a4',
	fontWeight: 600,
	'&.status': {
		paddingLeft: '50px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '10px',
		},
	},
	'&.labour': {
		padding: '15px 50px 15px 10px',
		[theme.breakpoints.down('md')]: {
			paddingLeft: 50,
		},
		[theme.breakpoints.down('sm')]: {
			padding: '15px 10px',
		},
	},
}));

const StatusBox = styled(Box)(({ theme }) => ({
	padding: '15px 10px 5px 50px',
	[theme.breakpoints.down('md')]: {
		border: '0.5px solid #dddfe2',
	},
	[theme.breakpoints.down('sm')]: {
		paddingLeft: '10px',
	},
}));

const FooterLabel = styled(Typography)({
	fontSize: 12,
	margin: '0 15px 5px 0',
	'& > span': {
		color: '#0859a4',
	},
});

interface InformationTabPanelProps {
	index: number;
	value: number;
	workOrder: Order;
	onClose: () => void;
}

const useInformationTabPanelStyles = makeStyles({
	root: {},
	select: {
		borderRadius: 0,
		fontSize: 12,
		'& li': {
			fontSize: 12,
		},
	},
	footer: {
		padding: '15px 10px',
		'& .warning': {
			marginLeft: 10,
			color: '#fa2e2e',
		},
		'& > .button': {
			margin: '10px 10px 0 0',
			borderRadius: 0,
			color: '#0859a4',
			textTransform: 'none',
			'&:hover': {
				backgroundColor: '#0859a4',
				color: '#ffffff',
			},
		},
	},
});

const StyledTableCell = styled(TableCell)({
	padding: 3,
	'&:not(:last-child)': {
		borderRight: '0.5px solid #dddfe2',
	},
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#ffffff',
		color: '#0859A4',
	},
	[`&.${tableCellClasses.body}`]: {},
});

const StyledTableRow = styled(TableRow)({
	padding: '3px 0',
	backgroundColor: '#ffffff',
});

const columns = ['Part No.', 'Description', 'Order Qty', 'Ship Qty', 'B/O Qty'];

const InformationTabPanel = ({ index, value, workOrder, ...props }: InformationTabPanelProps) => {
	const classes = useInformationTabPanelStyles();

	const dispatch = useAppDispatch();

	const employeesState = useEmployeesState();

	const [customOrderModalOpen, setCustomOrderModalOpen] = React.useState<boolean>(false);

	const handleAddCustomOrderModalOpen = () => {
		setCustomOrderModalOpen(true);
	};
	const handleAddCustomOrderModalClose = () => {
		dispatch(ordersAsyncActions.indexByIds({ ids: [workOrder.id] }));
		setCustomOrderModalOpen(false);
	};

	const handleDeleteCustomOrder = React.useCallback(
		(item: OrderItem) => {
			dispatch(ordersAsyncActions.deleteItem({ id: item.id }));
		},
		[dispatch]
	);

	const assignedTo = React.useMemo<string>(() => {
		for (const employee of employeesState.list) {
			const findIndex = employee.orders.findIndex((element) => element.orderId === workOrder.id);
			if (findIndex !== -1) {
				return employee.name;
			}
		}

		return '';
	}, [workOrder, employeesState.list, employeesState.updatedAt]);

	return (
		<Box className={classes.root} hidden={value !== index} {...props} p="15px 0 0 0">
			<AddCustomItemModal
				isEditing={true}
				orderId={workOrder.id}
				open={customOrderModalOpen}
				onClose={handleAddCustomOrderModalClose}
			/>
			<Grid container spacing={1} px={['10px', '50px']}>
				<Grid item xs={12} md={10}>
					<Grid container spacing={1}>
						<Grid item xs={12} md={2}>
							<FormLabel>Work Order</FormLabel>
							<CustomTextField value={workOrder.orderNo} disabled fullWidth size="small" />
						</Grid>
						<Grid item xs={12} md={2}>
							<FormLabel>Reference</FormLabel>
							<CustomTextField value={workOrder.referenceNo} disabled fullWidth size="small" />
						</Grid>
						<Grid item xs={12} md={2}>
							<FormLabel>Way bill</FormLabel>
							<CustomTextField value={workOrder.trackingNo} disabled fullWidth size="small" />
						</Grid>
						<Grid item xs={12} md={2}>
							<FormLabel>Order Date</FormLabel>
							<CustomTextField value={workOrder.orderDate} disabled fullWidth size="small" />
						</Grid>
						<Grid item xs={12} md={2}>
							<FormLabel>Required Date</FormLabel>
							<CustomTextField value={workOrder.requiredDate} disabled fullWidth size="small" />
						</Grid>
						<Grid item xs={12} md={2}>
							<FormLabel>Scheduled Date</FormLabel>
							<CustomTextField disabled fullWidth size="small" />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={2}>
					<FormLabel>Status</FormLabel>
					<CustomTextField disabled value={workOrder.type} fullWidth size="small" />
				</Grid>
				<Grid item xs={12} md={10}>
					<Grid container spacing={1}>
						<Grid item xs={12} md={4}>
							<FormLabel>Customer Name</FormLabel>
							<CustomTextField
								disabled
								value={workOrder.customer ? workOrder.customer.name : ''}
								fullWidth
								size="small"
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<FormLabel>Customer No.</FormLabel>
							<CustomTextField
								disabled
								value={workOrder.customer ? workOrder.customer.customerNo : ''}
								fullWidth
								size="small"
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<FormLabel>Location</FormLabel>
							<CustomTextField
								disabled
								value={workOrder.address ? workOrder.address.line1 : ''}
								fullWidth
								size="small"
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<FormLabel>Serial #</FormLabel>
							<CustomTextField
								disabled
								value={getUDFValue(workOrder.udfs, 'Serial #', '')}
								fullWidth
								size="small"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={2}>
					<FormLabel>Invoice Number</FormLabel>
					<CustomTextField value={workOrder.invoiceNo ?? ''} disabled fullWidth size="small" />
				</Grid>
			</Grid>
			<Divider light />
			<Grid container>
				<Grid item xs={12} md={3} borderRight="0.5px solid #dddfe2">
					<ContentLabel className="status">Work Order Status</ContentLabel>
					<Divider light />
					<StatusBox className="status">
						<FormLabel>Picked by</FormLabel>
						<CustomTextField disabled fullWidth size="small" value={workOrder.pickedBy ?? ''} />
						<FormLabel>Assigned to</FormLabel>
						<CustomTextField disabled fullWidth size="small" value={assignedTo} />
						<FormLabel>Previously Assigned to</FormLabel>
						<CustomTextField disabled fullWidth size="small" value={workOrder.previousEmployee ?? ''} />
					</StatusBox>
				</Grid>
				<Grid item xs={12} md={9}>
					<Box paddingRight="1em" display="flex" justifyContent="space-between" alignItems="centers">
						<ContentLabel className="labour">Worker Parts and Labour</ContentLabel>
						<Button onClick={handleAddCustomOrderModalOpen} size="small" variant="text">
							Add Item
						</Button>
					</Box>

					<Divider light />
					<TableContainer sx={{ maxHeight: 300 }}>
						<Table stickyHeader>
							<TableHead>
								<StyledTableRow>
									{columns.map((item, index) => (
										<StyledTableCell key={index}>{item}</StyledTableCell>
									))}
									<StyledTableCell>&nbsp;</StyledTableCell>
								</StyledTableRow>
							</TableHead>
							<TableBody>
								{workOrder.items.map((item, index) => (
									<StyledTableRow key={index}>
										<StyledTableCell>{item.partNo}</StyledTableCell>
										<StyledTableCell>{item.description}</StyledTableCell>
										<StyledTableCell>{item.orderQty}</StyledTableCell>
										<StyledTableCell>{item.commitedQty}</StyledTableCell>
										<StyledTableCell>{item.backorderQty}</StyledTableCell>
										<StyledTableCell>
											<Button
												onClick={() => handleDeleteCustomOrder(item)}
												size="small"
												variant="text"
											>
												Delete
											</Button>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
			<Divider light />
			<Grid container p={['10px 10px 0 10px', '50px 50px 0 50px']}>
				<Grid item xs={false} md={1} />
				<Grid item xs={12} md={5} component={Box} display="flex" flexWrap="wrap" alignItems="center">
					<FooterLabel>
						<span>Created by:</span> {workOrder.createdBy}
					</FooterLabel>
					<FooterLabel>
						<span>Created Date:</span> {moment.utc(workOrder.createdAt).format('MM/DD/YYYY HH:mm')}
					</FooterLabel>
				</Grid>
				<Grid item xs={false} md={1} />
				<Grid item xs={12} md={5} component={Box} display="flex" flexWrap="wrap" alignItems="center">
					<FooterLabel>
						<span>Modified by:</span> {workOrder.updatedBy}
					</FooterLabel>
					<FooterLabel>
						<span>Modified Date:</span> {moment.utc(workOrder.updatedAt).format('MM/DD/YYYY HH:mm')}
					</FooterLabel>
				</Grid>
			</Grid>
		</Box>
	);
};

export default InformationTabPanel;
