import { Udf } from '../models/Employee';
import getUDFValue from './getUDFValue';

const getAvailabilityText = (udfs: Udf[]) => {
	const value = getUDFValue(udfs, 'pg7_fld_001');
	if (value !== '') {
		return value;
	}

	return 'Unavailable';
};

export default getAvailabilityText;
