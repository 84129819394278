import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import ErrorResponse from 'network/responses/ErrorResponse';
import { toast } from 'react-hot-toast';
import { AServersState, CPA, PA, Timing } from 'store/types';
import AServer from '../../models/Admin/AServer';
import aServersAsyncActions from '../actions/aServers.action';
import authenticationAsyncActions from '../actions/authentication.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';

const initialState: AServersState = {
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'aServers',
	initialState,
	reducers: {
		lockCompanies: (
			state,
			{
				payload,
			}: PA<{
				serverId: string;
				companyIds: string[];
			}>
		) => {
			const findServerIndex = state.list.findIndex((element) => element.id === payload.serverId);
			if (findServerIndex !== -1) {
				const newServer = { ...state.list[findServerIndex] };
				for (const companyId of payload.companyIds) {
					const findIndex = newServer.companies.findIndex((element) => element.id === companyId);
					if (findIndex !== -1) {
						newServer.companies[findIndex].synchronizationLock = true;
					}
				}

				state.list.splice(findServerIndex, 1, newServer);
				state.updatedAt = Timing.now();
			}
		},
		unlockCompanies: (state, { payload }: PA<string>) => {
			const findServerIndex = state.list.findIndex((element) => element.id === payload);
			if (findServerIndex !== -1) {
				const newServer = { ...state.list[findServerIndex] };
				newServer.companies = newServer.companies.map((element) => ({
					...element,
					synchronizationLock: false,
				}));

				state.list.splice(findServerIndex, 1, newServer);
				state.updatedAt = Timing.now();
			}
		},
	},
	extraReducers: {
		[aServersAsyncActions.get.fulfilled.type]: (state, action: CPA<AServer>) => {
			const findServerIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findServerIndex !== -1) {
				const lockedCompanies = state.list[findServerIndex].companies.filter(
					(element) => element.synchronizationLock
				);

				const unlockedCompanies = action.payload.companies.filter((element) => !element.synchronizationLock);

				for (const unlockedCompany of unlockedCompanies) {
					const check = lockedCompanies.findIndex((element) => element.id === unlockedCompany.id);
					if (check !== -1) {
						if (unlockedCompany.lastSyncAt) {
							const now = moment.utc();

							if (now.diff(moment.utc(unlockedCompany.lastSyncAt), 'minutes') <= 5) {
								toast.success(`Synchronization of ${unlockedCompany.name} finished.`);
							}
						}
					}
				}

				state.list.splice(findServerIndex, 1, action.payload);
				state.updatedAt = Timing.now();
			}

			postRequest(action);
		},
		[aServersAsyncActions.index.fulfilled.type]: (state, action: CPA<AServer[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[aServersAsyncActions.store.fulfilled.type]: (state, action: CPA<AServer>) => {
			state.list.push(action.payload);
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[aServersAsyncActions.update.fulfilled.type]: (state, action: CPA<AServer>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
				state.updatedAt = Timing.now();
			}

			postRequest(action);
		},
		[aServersAsyncActions.destroy.fulfilled.type]: (state, action: CPA<Pick<AServer, 'id'>>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1);
				state.updatedAt = Timing.now();
			}

			postRequest(action);
		},
		[aServersAsyncActions.get.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aServersAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aServersAsyncActions.store.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aServersAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aServersAsyncActions.destroy.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
	},
});

export const aServersActions = slice.actions;

export default slice.reducer;
