import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../lib/utils/API';
import Filter from '../../models/Filter';
import FilterCreateRequest from '../../network/requests/FilterCreateRequest';
import FilterDeleteDefaultRequest from '../../network/requests/FilterDeleteDefaultRequest';
import FilterDeleteRequest from '../../network/requests/FilterDeleteRequest';
import FilterSetDefaultRequest from '../../network/requests/FilterSetDefaultRequest';
import FilterUpdateRequest from '../../network/requests/FilterUpdateRequest';
import OkResponse from '../../network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('filters/index', async (companyName: string, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<never, Filter[]>(`/filters/${companyName}`);

		thunkApi.dispatch(requestActions.beforeFulfilled(index.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(index.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { companyName }, error });
	}
});

const store = createAsyncThunk('filters/store', async ({ companyName, ...payload }: FilterCreateRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(store.typePrefix));
	try {
		const response = await API.post<Omit<FilterCreateRequest, 'companyName'>, Filter>(
			`/filters/${companyName}`,
			payload
		);

		thunkApi.dispatch(requestActions.beforeFulfilled(store.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(store.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { companyName }, error });
	}
});

const update = createAsyncThunk(
	'filters/update',
	async ({ companyName, ...payload }: FilterUpdateRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(update.typePrefix));
		try {
			const response = await API.put<Omit<FilterUpdateRequest, 'companyName'>, Filter>(
				`/filters/${companyName}`,
				payload
			);

			thunkApi.dispatch(requestActions.beforeFulfilled(update.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(update.typePrefix));
			console.log(error);
			return thunkApi.rejectWithValue({ payload: { companyName }, error });
		}
	}
);

const setDefault = createAsyncThunk(
	'filters/setDefault',
	async ({ companyName, ...payload }: FilterSetDefaultRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(setDefault.typePrefix));
		try {
			const response = await API.post<Omit<FilterSetDefaultRequest, 'companyName'>, Filter>(
				`/filters/${companyName}/default`,
				payload
			);

			thunkApi.dispatch(requestActions.beforeFulfilled(setDefault.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(setDefault.typePrefix));
			console.log(error);
			return thunkApi.rejectWithValue({ payload: { companyName }, error });
		}
	}
);

const destroy = createAsyncThunk('filters/destroy', async ({ filterId }: FilterDeleteRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(destroy.typePrefix));
	try {
		await API.delete<FilterDeleteRequest, OkResponse>(`/filters/${filterId}`);

		thunkApi.dispatch(requestActions.beforeFulfilled(destroy.typePrefix));
		return filterId;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(destroy.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { filterId }, error });
	}
});

const destroyDefault = createAsyncThunk(
	'filters/destroyDefault',
	async ({ companyName }: FilterDeleteDefaultRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(destroyDefault.typePrefix));
		try {
			await API.delete<Omit<FilterDeleteDefaultRequest, 'companyName'>, OkResponse>(
				`/filters/${companyName}/default`
			);

			thunkApi.dispatch(requestActions.beforeFulfilled(destroyDefault.typePrefix));
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(destroyDefault.typePrefix));
			console.log(error);
			return thunkApi.rejectWithValue({ payload: { companyName }, error });
		}
	}
);

const filtersAsyncActions = {
	index,
	store,
	update,
	setDefault,
	destroy,
	destroyDefault,
};

export default filtersAsyncActions;
