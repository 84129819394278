import moment from 'moment';
import { OrderSchedule } from '../models/Order';

const isOverlapping = (scheduleId: string, orders: OrderSchedule[], from: moment.Moment, to: moment.Moment) => {
	for (const order of orders) {
		if (order.id === scheduleId) {
			continue;
		}

		const list = order.startAt.split(',');
		for (let i = 0; i < list.length - 1; i += 2) {
			const tempStartAt = moment.utc(list[i]);
			const tempEndAt = moment.utc(list[i + 1]);

			if (from.isBetween(tempStartAt, tempEndAt) || to.isBetween(tempStartAt, tempEndAt)) {
				console.error(`Overlapping with order ${order.orderId}`);
				return true;
			}

			if (tempStartAt.isBetween(from, to) || tempEndAt.isBetween(from, to)) {
				console.error(`Overlapping with order ${order.orderId}`);
				return true;
			}
		}
	}

	return false;
};

export default isOverlapping;
