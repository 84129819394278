import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import API from '../../lib/utils/API';
import AServer from '../../models/Admin/AServer';
import ServerCreateRequest from '../../network/requests/Admin/ServerCreateRequest';
import ServerDeleteRequest from '../../network/requests/Admin/ServerDeleteRequest';
import ServerGetRequest from '../../network/requests/Admin/ServerGetRequest';
import ServerUpdateRequest from '../../network/requests/Admin/ServerUpdateRequest';
import OkResponse from '../../network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';

const get = createAsyncThunk('aServers/get', async (payload: ServerGetRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(get.typePrefix));
	try {
		const response = await API.get<ServerGetRequest, AxiosResponse<AServer>>(`/admin/servers/${payload.id}`);

		thunkApi.dispatch(requestActions.beforeFulfilled(get.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(get.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: {}, error });
	}
});

const index = createAsyncThunk('aServers/index', async (_, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, AxiosResponse<AServer[]>>('/admin/servers');

		thunkApi.dispatch(requestActions.beforeFulfilled(index.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(index.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: {}, error });
	}
});

const store = createAsyncThunk('aServers/store', async (payload: ServerCreateRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(store.typePrefix));
	try {
		const response = await API.post<ServerCreateRequest, AServer>('/admin/servers', payload);

		thunkApi.dispatch(requestActions.beforeFulfilled(store.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(store.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const update = createAsyncThunk('aServers/update', async ({ id, ...payload }: ServerUpdateRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	try {
		const response = await API.put<Omit<ServerUpdateRequest, 'id'>, AxiosResponse<AServer>>(
			`/admin/servers/${id}`,
			payload
		);

		thunkApi.dispatch(requestActions.beforeFulfilled(update.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(update.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const destroy = createAsyncThunk('aServers/destroy', async (payload: ServerDeleteRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(destroy.typePrefix));
	try {
		await API.delete<ServerDeleteRequest, AxiosResponse<OkResponse>>(`/admin/servers/${payload.id}`);

		thunkApi.dispatch(requestActions.beforeFulfilled(destroy.typePrefix));
		return payload;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(destroy.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const aServersAsyncActions = {
	get,
	index,
	store,
	update,
	destroy,
};

export default aServersAsyncActions;
