import moment from 'moment';
import Employee, { Hours } from '../models/Employee';

const getAvailableMinutes = (employee: Employee, day: string, from: string): number => {
	const keyTo = `${day.toLowerCase()}To` as keyof Hours;

	const to = employee.hours![keyTo];

	if (to === null) {
		return 0;
	}

	const nowFrom = moment.utc(`2000-01-01 ${from}:00`, 'YYYY-MM-DD HH:mm:ss');
	const nowTo = moment.utc(`2000-01-01 ${to}:00`, 'YYYY-MM-DD HH:mm:ss');

	return Math.abs(nowFrom.diff(nowTo, 'minutes'));
};

export default getAvailableMinutes;
