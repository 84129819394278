import React, { CSSProperties } from 'react';
import { Color } from 'types';
import classes from './index.module.scss';

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'subheading1' | 'subheading2' | 'body1' | 'body2';
type VariantMapping = Record<Variant, string>;
type Weight = 'light' | 'regular' | 'medium' | 'bold' | 'heavy';

// Defining the HTML tag that the component will support
const variantsMapping: VariantMapping = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	h5: 'h5',
	h6: 'h6',
	p: 'p',
	subheading1: 'h6',
	subheading2: 'h6',
	body1: 'p',
	body2: 'p',
};

interface Props {
	children: React.ReactNode;
	variant?: Variant;
	weight?: Weight;
	color?: Color;
	className?: string;
	style?: CSSProperties;
}

const Typography = ({ variant = 'p', color, weight, className = '', children, style }: Props) => {
	const cmpName = variant ? variantsMapping[variant] : 'p';

	const variantClassName = `typography-variant-${variant}`;
	const colorClassName = color ? `typography-color-${color}` : '';
	const weightClassName = weight ? `typography-weight-${weight}` : '';
	const combinedClassNames = `${classes[variantClassName]} ${classes[colorClassName]} ${classes[weightClassName]} ${className}`;

	return React.createElement(cmpName, { className: combinedClassNames, style }, children);
};

export default Typography;
