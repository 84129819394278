import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../lib/utils/API';
import SearchAdvancedRequest from '../../network/requests/SearchAdvancedRequest';
import SearchRequest from '../../network/requests/SearchRequest';
import { requestActions } from '../slices/request.slice';

const search = createAsyncThunk('search/index', async ({ companyName, ...payload }: SearchRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(search.typePrefix));
	try {
		const response = await API.post<Omit<SearchRequest, 'companyName'>, string[]>(
			`/search/${companyName}`,
			payload
		);

		thunkApi.dispatch(requestActions.beforeFulfilled(search.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(search.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { companyName }, error });
	}
});

const advanced = createAsyncThunk(
	'search/advanced',
	async ({ companyName, ...payload }: SearchAdvancedRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(advanced.typePrefix));
		try {
			const response = await API.post<Omit<SearchAdvancedRequest, 'companyName'>, string[]>(
				`/search/${companyName}/advanced`,
				payload
			);
			thunkApi.dispatch(requestActions.beforeFulfilled(advanced.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(advanced.typePrefix));
			console.log(error);
			return thunkApi.rejectWithValue({ payload: { companyName }, error });
		}
	}
);

const searchAsyncActions = {
	search,
	advanced,
};

export default searchAsyncActions;
