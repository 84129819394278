import { Udf } from '../models/Employee';

const getUDFValue = (udfs: Array<Udf>, name: string, defaultValue: string = '') => {
	const find = udfs.find((element) => element.name === name);
	if (find) {
		if (find.value === '' || find.value === null) {
			return defaultValue;
		}

		return find.value;
	}

	return defaultValue;
};

export default getUDFValue;
