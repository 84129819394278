import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import API from '../../lib/utils/API';
import AUser from '../../models/Admin/AUser';
import UserBlockRequest from '../../network/requests/Admin/UserBlockRequest';
import UserCreateRequest from '../../network/requests/Admin/UserCreateRequest';
import UserDeleteRequest from '../../network/requests/Admin/UserDeleteRequest';
import UserUpdateRequest from '../../network/requests/Admin/UserUpdateRequest';
import OkResponse from '../../network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('aUsers/index', async (_, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, AxiosResponse<AUser[]>>('/admin/users');

		thunkApi.dispatch(requestActions.beforeFulfilled(index.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(index.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: {}, error });
	}
});

const store = createAsyncThunk('aUsers/store', async (payload: UserCreateRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(store.typePrefix));
	try {
		const response = await API.post<UserCreateRequest, AUser>('/admin/users', payload);

		thunkApi.dispatch(requestActions.beforeFulfilled(store.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(store.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const update = createAsyncThunk('aUsers/update', async ({ id, ...payload }: UserUpdateRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	try {
		const response = await API.put<Omit<UserUpdateRequest, 'id'>, AxiosResponse<AUser>>(
			`/admin/users/${id}`,
			payload
		);

		thunkApi.dispatch(requestActions.beforeFulfilled(update.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(update.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const block = createAsyncThunk('aUsers/block', async (payload: UserBlockRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(block.typePrefix));
	try {
		const response = await API.get<UserBlockRequest, AxiosResponse<AUser>>(`/admin/users/${payload.id}`);

		thunkApi.dispatch(requestActions.beforeFulfilled(block.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(block.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const destroy = createAsyncThunk('aUsers/destroy', async (payload: UserDeleteRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(destroy.typePrefix));
	try {
		await API.delete<UserDeleteRequest, AxiosResponse<OkResponse>>(`/admin/users/${payload.id}`);

		thunkApi.dispatch(requestActions.beforeFulfilled(destroy.typePrefix));
		return payload;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(destroy.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const aUsersAsyncActions = {
	index,
	store,
	update,
	block,
	destroy,
};

export default aUsersAsyncActions;
