import Company from 'models/Company';
import Employee from 'models/Employee';
import { RootState, useTypedSelector } from '.';
import {
	AAdminsState,
	AServersState,
	ASyncUsersState,
	AUsersState,
	AuthenticationState,
	CalendarState,
	FiltersState,
	InventoryItemsState,
	MultiDragState,
	OrderPhasesState,
	OrdersState,
	Request,
	SchedulerState,
	SearchState,
	SliceState,
	UIState,
} from './types';

//! Selector hooks
export function useSelectState() {
	return useTypedSelector<RootState>((state) => state);
}

export function useRootState() {
	return useTypedSelector<RootState>((root) => root);
}

export function useAuthenticationState() {
	return useTypedSelector<AuthenticationState>(({ authentication }) => authentication);
}

export function useUIState() {
	return useTypedSelector<UIState>(({ ui }) => ui);
}

export const useCalendarState = () => useTypedSelector<CalendarState>((state) => state.calendar);

export const useMultidragState = () => useTypedSelector<MultiDragState>((state) => state.multidrag);

export const useOrderPhasesState = () => useTypedSelector<OrderPhasesState>((state) => state.orderPhases);

export const useAUsersState = () => useTypedSelector<AUsersState>((state) => state.aUsers);

export const useAAdminsState = () => useTypedSelector<AAdminsState>((state) => state.aAdmins);

export const useAServersState = () => useTypedSelector<AServersState>((state) => state.aServers);

export const useASyncUsersState = () => useTypedSelector<ASyncUsersState>((state) => state.aSyncUsers);

export const useSearchState = () => useTypedSelector<SearchState>((state) => state.search);

export const useSchedulerState = () => useTypedSelector<SchedulerState>((state) => state.scheduler);

export const useInventoryItemsState = () => useTypedSelector<InventoryItemsState>((state) => state.inventoryItems);

export const useFiltersState = () => useTypedSelector<FiltersState>((state) => state.filters);

export const useRequestState = () => useTypedSelector<Request.State>((state) => state.request);

export const useCompaniesState = () => {
	return useTypedSelector<SliceState<Company>>((state) => state.companies);
};

export const useEmployeesState = () => {
	return useTypedSelector<SliceState<Employee>>((state) => state.employees);
};

export const useOrdersState = () => {
	return useTypedSelector<OrdersState>((state) => state.orders);
};
