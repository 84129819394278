import React from 'react';
import { Udf } from '../models/Employee';
import { IcErrorBadge, IcOrangeArrowBadge, IcSuccessBadge, IcYellowArrowBadge } from '../res/icons';
import getUDFValue from './getUDFValue';

const getAvailability = (udfs: Udf[]) => {
	const value = getUDFValue(udfs, 'pg7_fld_001');

	if (value === 'Available') {
		return <IcSuccessBadge />;
	} else if (value === 'Busy') {
		return <IcOrangeArrowBadge />;
	} else if (value === 'On Lunch') {
		return <IcYellowArrowBadge />;
	}

	return <IcErrorBadge />;
};

export default getAvailability;
