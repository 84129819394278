import { Box, Button } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { makeStyles, styled } from '@mui/styles';
import AppHeader from 'lib/components/AppHeader';
import { Breadcrumbs } from 'lib/components/Common';
import LogoutModal from 'lib/components/LogoutModal';
import { Calendar, Schedular, WorkOrder } from 'lib/components/ScheduleBoard';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { IcHome } from 'res/icons';
import { useTypedSelector } from 'store';
import { useCompaniesState, useUIState } from '../../store/selectors';
import { ordersActions } from '../../store/slices/orders.slice';
import { Image } from 'lib/components';

const useScheduleBoardStyles = makeStyles({
	root: {
		height: '100vh',
		overflowY: 'hidden',
	},
	breadcrumbs: {
		paddingLeft: '2em',
		height: '50px',
	},
});

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
	() => ({
		minWidth: 200,
		backgroundColor: '#FFFFFF',
	})
);

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(() => ({
	padding: 0,
	minHeight: 0,

	'& .MuiAccordionSummary-content': {
		margin: 0,
		padding: 0,
	},
	'& .expand-icon': {
		transition: 'transform ease .3s',
		'.Mui-expanded &': {
			transform: 'rotate(180deg)',
		},
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
	padding: 0,
	borderTop: '1px solid rgba(0, 0, 0, .125)',
	'& .MuiMenuItem-root': {
		justifyContent: 'space-between',
		'&:not(:last-child)': {
			borderBottom: `1px solid #dddfe2`,
		},
	},
}));

const ScheduleBoardScreen = () => {
	const dispatch = useDispatch();

	const uiState = useUIState();

	const classes = useScheduleBoardStyles();
	const visibility = useTypedSelector((state) => state.ui);
	const params = useParams<{
		companyName: string;
	}>();
	const [openLogoutModal, setOpenLogoutModal] = React.useState(false);

	const companiesState = useCompaniesState();

	const currentCompany = React.useMemo<string>(() => {
		const company = companiesState.list.find((element) => element.name === params.companyName);

		if (company && company.description) {
			return company.description;
		}

		return '';
	}, [params.companyName]);

	const closeModal = () => {
		setOpenLogoutModal(false);
	};
	const openModal = () => {
		setOpenLogoutModal(true);
	};

	React.useEffect(() => {
		dispatch(ordersActions.sort());
	}, []);

	return (
		<Box className={classes.root}>
			<LogoutModal onclose={closeModal} open={openLogoutModal} />
			<Accordion expanded={uiState.calenderVisible}>
				<AccordionSummary></AccordionSummary>
				<AccordionDetails>
					<Box>
						<AppHeader />
						<Box display="flex">
							<Breadcrumbs className={classes.breadcrumbs}>
								<Link className="nav-item" to="/">
									<IcHome />
								</Link>
								<Link className="nav-item" to="/selectcompany">
									{currentCompany}
								</Link>
								<Link className="nav-item" to={`/company/${params.companyName}/dashboard`}>
									Dashboard
								</Link>
								<Link className="nav-item active" to={`/company/${params.companyName}/scheduleboard`}>
									Schedule Board
								</Link>
							</Breadcrumbs>
							<Button sx={{ marginLeft: 'auto' }} onClick={openModal} color="error">
								Sign out
							</Button>
						</Box>
					</Box>
				</AccordionDetails>
			</Accordion>

			<Box display="flex" flexWrap="wrap">
				<Box hidden={!visibility.leftPanelVisible} flexBasis={['100%', 250]}>
					<WorkOrder />
				</Box>
				<Box flex="auto" maxWidth={visibility.leftPanelVisible ? ['100%', 'calc(100% - 250px)'] : ['100%']}>
					<Calendar></Calendar>
					<Schedular></Schedular>
				</Box>
			</Box>
		</Box>
	);
};

export default ScheduleBoardScreen;
