import React, { useState } from 'react';
import { Box, Button, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

import { ColorResult, CustomPicker, InjectedColorProps, SketchPicker } from 'react-color';
// var {} = require('react-color/lib/components/common');

interface ColorPickerProps extends InjectedColorProps {
	open: boolean;
	onClose: () => void;
	color: string;
	setColor: (color: string) => void;
}

const useColorPickerStyles = makeStyles({
	root: {
		'& .MuiPaper-root': {
			padding: '10px 10px',
		},
	},
});

const CustomButton = styled(Button)({
	fontSize: 12,
	textTransform: 'none',
	borderRadius: 0,
	padding: 5,
	marginRight: 5,
	borderColor: '#0859a4',
	color: '#0859a4',
	'&:hover': {
		color: '#ffffff',
		backgroundColor: '#0859a4',
	},
});

const ColorPicker = (props: ColorPickerProps) => {
	const { setColor, color, open, onClose } = props;
	const classes = useColorPickerStyles();
	const [localColor, setLocalColor] = useState<string>(color);

	const handleChange = (data: ColorResult) => {
		setLocalColor(data.hex);
	};

	const handleConfirmed = () => {
		setColor(localColor);
		onClose();
	};

	return (
		<Dialog maxWidth="xs" className={classes.root} open={open}>
			<Box sx={{ padding: '1em' }} display="flex">
				<SketchPicker
					width="100%"
					{...props}
					color={localColor}
					onChange={handleChange}
					// onChangeComplete={(color) => {
					// 	setColor(color.hex);
					// }}
				/>
			</Box>
			<Box p="10px 0" display="flex" alignItems="center">
				<CustomButton variant="outlined" onClick={handleConfirmed}>
					Pick Color
				</CustomButton>
				<CustomButton variant="outlined" onClick={onClose}>
					Cancel
				</CustomButton>
			</Box>
		</Dialog>
	);
};

// function Picker() {
// 	return (
// 		<Box
// 			width={20}
// 			height={20}
// 			borderRadius={5}
// 			mt="-10px"
// 			m="-10px"
// 			border="2px solid white"
// 		/>
// 	);
// }

// function HuePicker() {
// 	return (
// 		<Box
// 			width={20}
// 			height={10}
// 			borderRadius={5}
// 			mt="-5px"
// 			m="-5px"
// 			border="2px solid white"
// 			style={{
// 				backgroundColor: '#1708ff',
// 			}}
// 		/>
// 	);
// }

export default CustomPicker(ColorPicker);
