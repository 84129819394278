import { Box, Button, Dialog, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import AAdmin from '../../../models/Admin/AAdmin';
import { useAppDispatch } from '../../../store';
import aAdminsAsyncActions from '../../../store/actions/aAdmins.action';
const useStyles = makeStyles({
	root: {
		'& .MuiPaper-root': {
			backgroundColor: '#f1f1f1',
		},
	},
});
export interface Props {
	user?: AAdmin | null;
	open: boolean;
	onClose: () => void;
}
const CreateAdminModal: React.FC<Props> = ({ user, open, onClose }) => {
	const classes = useStyles();

	const dispatch = useAppDispatch();

	const [firstName, setFirstName] = React.useState<string>('');
	const [lastName, setLastName] = React.useState<string>('');
	const [username, setUsername] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');

	React.useEffect(() => {
		if (!open || !user) {
			return;
		}

		setFirstName(user.firstName ?? '');
		setLastName(user.lastName ?? '');
		setUsername(user.username);
	}, [open]);

	return (
		<Dialog className={classes.root} open={open} fullWidth maxWidth="sm">
			<Box flex={1} padding={'2em'}>
				<Grid spacing={2} container>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'First Name'}
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Last Name'}
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Username'}
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Password'}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							fullWidth
							type={'password'}
							size="small"
							variant="outlined"
						/>
					</Grid>
				</Grid>
				<Box height={48} />
				<Grid spacing={2} container>
					<Grid item xs={6} sm={6} md={3}>
						<Button
							onClick={() => {
								if (user) {
									dispatch(
										aAdminsAsyncActions.update({
											id: user.id,
											firstName,
											lastName,
											username,
											password,
										})
									);
								} else {
									dispatch(
										aAdminsAsyncActions.store({
											firstName,
											lastName,
											username,
											password,
										})
									);
								}
								onClose();
							}}
							sx={{ height: '40px' }}
							fullWidth
							variant="contained"
						>
							Save
						</Button>
					</Grid>
					<Grid item xs={6} sm={6} md={3}>
						<Button sx={{ height: '40px' }} onClick={onClose} fullWidth variant="outlined">
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	);
};

export default CreateAdminModal;
