import { useState, useEffect } from 'react';

/**
 * Effect hook for handling search
 * @param query Search query
 * @param minCharactersCallback What to do when query has less than 3 chars (eg. Disable loading indicators)
 * @param searchFunction Callback containing search logic
 */
export default function useSearch(
	query: string,
	minCharactersCallback: () => void,
	searchFunction: (...args: any[]) => void
) {
	const [finishedSearch, setFinishedSearch] = useState(false);

	useEffect(() => {
		if (finishedSearch) {
			setFinishedSearch(false);
		}

		const timeoutHandle = setTimeout(() => {
			searchFunction();
			setFinishedSearch(true);
		}, 500);

		return () => {
			if (timeoutHandle) {
				clearTimeout(timeoutHandle);
			}
		};
	}, [query]);

	return { finishedSearch };
}
