export enum LoadingState {
	IDLE = 'idle',
	PENDING = 'pending',
	SUCCEEDED = 'succeeded',
	FAILED = 'failed',
}

export enum Genders {
	MALE = 1,
	FEMALE = 2,
}

export enum DataType {
	Boolean = 'BOOLEAN',
	Text = 'TEXT',
}

export enum WidgetType {
	Checkbox = 'CHECKBOX',
	DropDown = 'DROP_DOWN',
	LineEdit = 'LINE_EDIT',
	DateEdit = 'DATE_EDIT',
}
