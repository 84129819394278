import { createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { OrderPhase } from 'models/Order';
import ErrorResponse from 'network/responses/ErrorResponse';
import OkResponse from 'network/responses/OkResponse';
import { CPA, OrderPhasesState, PA, Timing } from 'store/types';
import authenticationAsyncActions from '../actions/authentication.action';
import orderPhasesAsyncActions from '../actions/orderPhases.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';

const initialState: OrderPhasesState = {
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'orderPhases',
	initialState,
	reducers: {
		setDefault: (state: WritableDraft<typeof initialState>, action: PA<{ id: string }>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				for (let i = 0; i < state.list.length; i++) {
					if (state.list[i].phaseType === state.list[findIndex].phaseType) {
						state.list[i].isDefault = false;
					}
				}

				state.list.splice(findIndex, 1, {
					...state.list[findIndex],
					isDefault: true,
				});

				state.updatedAt = Timing.now();
			}
		},
		clear: () => initialState,
	},
	extraReducers: {
		[orderPhasesAsyncActions.update.fulfilled.type]: (state, action: CPA<OrderPhase>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
				state.updatedAt = Timing.now();
			}

			postRequest(action);
		},
		[orderPhasesAsyncActions.setDefault.fulfilled.type]: (state, action: CPA<OrderPhase>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
				state.updatedAt = Timing.now();
			}

			postRequest(action);
		},
		[orderPhasesAsyncActions.freightVerifySync.fulfilled.type]: (state, action: CPA<OkResponse>) =>
			postRequest(action),
		[orderPhasesAsyncActions.index.fulfilled.type]: (state, action: CPA<OrderPhase[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[orderPhasesAsyncActions.sync.fulfilled.type]: (state, action: CPA<OrderPhase[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[orderPhasesAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[orderPhasesAsyncActions.setDefault.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[orderPhasesAsyncActions.freightVerifySync.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[orderPhasesAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[orderPhasesAsyncActions.sync.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signIn.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
	},
});

export const orderPhasesActions = slice.actions;

export default slice.reducer;
