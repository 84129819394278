import React, { useState } from 'react';
import { Typography } from 'lib/components';
import { Box, Dialog, IconButton, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { IcDialogClose } from 'res/icons';
import StatusTabPanel from './StatusTabPanel';
import InformationTabPanel from './InformationTabPanel';
import ColorPicker from '../Scheduler/WorkDialog/ColorPicker';
import Order from 'models/Order';

interface WorkOrderItemDialogProps {
	open: boolean;
	workOrder: Order;
	onClose: () => void;
}

const useWorkDialogStyles = makeStyles({
	root: {
		'& .MuiPaper-root': {
			backgroundColor: '#f1f1f1',
		},
	},
	header: {
		padding: '15px 10px',
		position: 'relative',
		'& > .title': {
			textAlign: 'center',
			color: '#0859a4',
			fontWeight: '600',
		},
		'& > .icon-buttons': {
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			right: 5,
			padding: 0,
			display: 'flex',
			'& > .MuiIconButton-root': {
				height: 20,
				width: 20,
				padding: 0,
				marginRight: 5,
			},
		},
	},
	body: {
		paddingTop: 15,
		padding: '15px 0',
		'& > .tabs': {
			borderBottom: '0.5px solid #dddfe2',
			minHeight: 0,
			'& .MuiTab-root': {
				fontSize: 14,
				padding: '3px 5px',
				textTransform: 'none',
				minHeight: 0,
				minWidth: 0,
				'&[aria-selected="true"]': {
					color: '#0859a4',
				},
			},
		},
	},
	select: {
		borderRadius: 0,
		fontSize: 12,
		'& li': {
			fontSize: 12,
		},
	},
	footer: {
		padding: '15px 10px',
		'& .warning': {
			marginLeft: 10,
			color: '#fa2e2e',
		},
		'& > .button': {
			margin: '10px 10px 0 0',
			borderRadius: 0,
			color: '#0859a4',
			textTransform: 'none',
			'&:hover': {
				backgroundColor: '#0859a4',
				color: '#ffffff',
			},
		},
	},
});

const WorkOrderItemDialog: React.FC<WorkOrderItemDialogProps> = ({ onClose, open, workOrder }) => {
	const classes = useWorkDialogStyles();

	const [value, setValue] = useState(0);
	const [color, setColor] = useState('#FFF000');

	const [colorPickerOpen, setColorPickerOpen] = useState(false);

	// const [startTime, setStartTime] = useState<Date | null>(new Date());
	// const [endTime, setEndTime] = useState<Date | null>(new Date());

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Dialog className={classes.root} open={open} fullWidth maxWidth="lg">
			<Box className={classes.header}>
				<Typography variant="p" className="title">
					Work Order: #{workOrder?.orderNo}
				</Typography>
				<Box className="icon-buttons">
					<IconButton onClick={onClose}>
						<IcDialogClose />
					</IconButton>
				</Box>
			</Box>

			<Box className={classes.body}>
				<Tabs value={value} className="tabs" onChange={handleChange} centered>
					<Tab label="Status" />
					<Tab label="Information" />
				</Tabs>
				<StatusTabPanel onClose={onClose} index={0} workOrder={workOrder} value={value} />
				<InformationTabPanel value={value} index={1} workOrder={workOrder} onClose={onClose} />
			</Box>
			<ColorPicker
				color={color}
				setColor={setColor}
				open={colorPickerOpen}
				onClose={() => setColorPickerOpen(false)}
			></ColorPicker>
		</Dialog>
	);
};

export default WorkOrderItemDialog;
