import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AppHeader from 'lib/components/AppHeader';
import LogoutModal from 'lib/components/LogoutModal';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { IcHome } from 'res/icons';
import { Breadcrumbs } from '../../lib/components/Common';
import { useAppDispatch } from '../../store';
import { useAServersState, useASyncUsersState } from '../../store/selectors';
import { aServersActions } from '../../store/slices/aServers.slice';
import aAdminsAsyncActions from '../../store/actions/aAdmins.action';
import aServersAsyncActions from '../../store/actions/aServers.action';
import aSyncUsersAsyncActions from '../../store/actions/aSyncUsers.action';
import aUsersAsyncActions from '../../store/actions/aUsers.action';
import AdminsTab from './Components/AdminsTab';
import CreateAdminModal from './Components/CreateAdminModal';
import CreateServerModal from './Components/CreateServerModal';
import CreateSyncUserModal from './Components/CreateSyncUserModal';
import CreateUserModal from './Components/CreateUserModal';
import ServersTab from './Components/ServersTab';
import SyncUsersTab from './Components/SyncUsersTab';
import UsersTab from './Components/UsersTab';

const useScheduleBoardStyles = makeStyles({
	root: {
		height: '100vh',
		overflowY: 'hidden',
	},
	breadcrumbs: {
		paddingLeft: '2em',
		height: '50px',
	},
});

const AdminPortalScreen = () => {
	const dispatch = useAppDispatch();

	const classes = useScheduleBoardStyles();

	const aSyncUsersState = useASyncUsersState();
	const aServersState = useAServersState();

	const [tabIndex, setTabIndex] = useState('0');
	const handleChange = (_: React.SyntheticEvent, newValue: string) => {
		setTabIndex(newValue);
	};
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [openLogoutModal, setOpenLogoutModal] = React.useState(false);

	const closeModal = () => {
		setOpenLogoutModal(false);
	};
	const openModal = () => {
		setOpenLogoutModal(true);
	};
	const closeDialog = () => {
		setCreateModalOpen(false);
	};
	const openDialog = () => {
		setCreateModalOpen(true);
	};

	const checkCompany = (serverId: string, counter = 0) => {
		const server = aServersState.list.find((element) => element.id === serverId)!;

		// Wait for 2 minutes.
		if (counter >= 60 * 2) {
			toast.error(`Synchronization of ${server.name} failed. 2 minutes passed.`);
			dispatch(aServersActions.unlockCompanies(serverId));
			return;
		}

		setTimeout(() => {
			dispatch(aServersAsyncActions.get({ id: serverId }));
		}, 3000);

		// axios
		// 	.get(`${process.env.REACT_APP_API_URL!}/synchronization/${serverId}.lock`, {
		// 		method: 'HEAD',
		// 	})
		// 	.then(() => {
		// 		setTimeout(() => checkCompany(serverId, counter + 1), 1000);
		// 	})
		// 	.catch(() => {
		// 		toast.success(`Synchronization of ${serverId} finished.`);
		// 		dispatch(aSyncUsersActions.removeFromSynchronize(serverId));
		// 	});
	};

	React.useEffect(() => {
		for (const server of aServersState.list) {
			// Nothing to synchronize
			if (server.companies.every((element) => element.synchronizationLock === false)) {
				continue;
			}

			checkCompany(server.id);
		}
	}, [aServersState.list]);

	React.useEffect(() => {
		dispatch(aUsersAsyncActions.index());
		dispatch(aServersAsyncActions.index());
		dispatch(aAdminsAsyncActions.index());
		dispatch(aSyncUsersAsyncActions.index());
	}, []);

	React.useEffect(() => {
		if (aSyncUsersState.lastCreatedId !== null) {
			dispatch(aServersAsyncActions.index());
		}
	}, [aSyncUsersState.lastCreatedId]);

	// React.useEffect(() => {
	// 	setTimeout(() => {
	// 		for (const company of aSyncUsersState.synchronizationProgress) {
	// 			checkCompany(company);
	// 		}
	// 	}, 2000);
	// }, [aSyncUsersState.synchronizationProgress]);

	return (
		<Box className={classes.root}>
			<Box>
				<LogoutModal onclose={closeModal} open={openLogoutModal} />
				{tabIndex === '0' && <CreateServerModal onClose={closeDialog} open={createModalOpen} />}
				{tabIndex === '1' && <CreateUserModal onClose={closeDialog} open={createModalOpen} />}
				{tabIndex === '2' && <CreateAdminModal onClose={closeDialog} open={createModalOpen} />}
				{tabIndex === '3' && <CreateSyncUserModal onClose={closeDialog} open={createModalOpen} />}
				<AppHeader />
				<Box display="flex">
					<Breadcrumbs className={classes.breadcrumbs}>
						<Link className="nav-item" to="/">
							<IcHome />
						</Link>
						<Link className="nav-item active" to="/dashboard">
							ADMINISTRATION PANEL
						</Link>
					</Breadcrumbs>
					<Button sx={{ marginLeft: 'auto' }} onClick={openModal} color="error">
						Sign out
					</Button>
				</Box>
			</Box>

			<Box>
				<TabContext value={tabIndex}>
					<Box
						display="flex"
						sx={{ borderBottom: 1, borderTop: 1, borderColor: 'divider', alignItems: 'center' }}
					>
						<TabList onChange={handleChange}>
							<Tab value="0" key="0" label="SERVERS" />
							<Tab value="1" key="1" label="USERS" />
							<Tab value="2" key="2" label="ADMINISTRATORS" />
							<Tab value="3" key="3" label="SYNC" />
						</TabList>
						<Button
							onClick={openDialog}
							variant="contained"
							sx={{
								marginLeft: 'auto',
								marginRight: '1em',
								height: 40,
								boxShadow: 'none',
							}}
						>
							Create new
						</Button>
					</Box>
					<TabPanel value="0">
						<ServersTab />
					</TabPanel>
					<TabPanel value="1">
						<UsersTab />
					</TabPanel>
					<TabPanel value="2">
						<AdminsTab />
					</TabPanel>
					<TabPanel value="3">
						<SyncUsersTab />
					</TabPanel>
				</TabContext>
			</Box>
		</Box>
	);
};

export default AdminPortalScreen;
