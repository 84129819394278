import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import aAdmins from './slices/aAdmins.slice';
import aServers from './slices/aServers.slice';
import aSyncUsers from './slices/aSyncUsers.slice';
import aUsers from './slices/aUsers.slice';
import authentication from './slices/authentication.slice';
import calendar from './slices/calendar.slice';
import companies from './slices/companies.slice';
import employees from './slices/employees.slice';
import filters from './slices/filters.slice';
import inventoryItems from './slices/inventoryItems.slice';
import multidrag from './slices/multidrag.slice';
import orderPhases from './slices/orderPhases.slice';
import orders from './slices/orders.slice';
import request from './slices/request.slice';
import scheduler from './slices/scheduler.slice';
import search from './slices/search.slice';
import ui from './slices/ui.slice';

const reducer = combineReducers({
	aAdmins,
	aServers,
	aSyncUsers,
	aUsers,
	authentication,
	calendar,
	companies,
	employees,
	filters,
	inventoryItems,
	multidrag,
	orderPhases,
	orders,
	request,
	scheduler,
	search,
	ui,
});

const preloadedState = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')!) : {};

// This middleware will just add the property "async dispatch" to all actions
// @ts-ignore
const asyncDispatchMiddleware = (store) => (next) => (action) => {
	let syncActivityFinished = false;
	let actionQueue: Array<any> = [];

	function flushQueue() {
		actionQueue.forEach((a) => store.dispatch(a)); // flush queue
		actionQueue = [];
	}

	function dispatch(asyncAction: any) {
		actionQueue = actionQueue.concat([asyncAction]);

		if (syncActivityFinished) {
			flushQueue();
		}
	}

	const actionWithAsyncDispatch = Object.assign({}, action, { dispatch });

	const res = next(actionWithAsyncDispatch);

	syncActivityFinished = true;
	flushQueue();

	return res;
};

const store = configureStore({
	reducer,
	preloadedState,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(asyncDispatchMiddleware),
	devTools: process.env.NODE_ENV === 'development',
});

store.subscribe(() => {
	try {
		let { orders, request, ...remainingState } = store.getState();
		localStorage.setItem('state', JSON.stringify(remainingState));
	} catch (error) {
		localStorage.clear();
		// console.error('error setting persist state in local storage :', error);
	}
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
