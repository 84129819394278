import { createAsyncThunk } from '@reduxjs/toolkit';
import Order from 'models/Order';
import API from '../../lib/utils/API';
import OrderAddItemRequest from '../../network/requests/OrderAddItemRequest';
import OrderDeleteItemRequest from '../../network/requests/OrderDeleteItemRequest';
import OrdersByIdsRequest from '../../network/requests/OrdersByIdsRequest';
import OrdersListRequest from '../../network/requests/OrdersListRequest';
import OrdersScheduledListRequest from '../../network/requests/OrdersScheduledListRequest';
import OrderUpdateRequest from '../../network/requests/OrderUpdateRequest';
import OkResponse from '../../network/responses/OkResponse';
import PaginatedResponse from '../../network/responses/PaginatedResponse';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('orders/index', async ({ name, page, limit }: OrdersListRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, PaginatedResponse<Order>>(`/orders/${name}?page=${page}&limit=${limit}`);

		thunkApi.dispatch(requestActions.beforeFulfilled(index.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(index.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { companyName: name }, error });
	}
});

const indexScheduled = createAsyncThunk(
	'orders/index-scheduled',
	async ({ name }: OrdersScheduledListRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(indexScheduled.typePrefix));
		try {
			const response = await API.get<any, Order[]>(`/orders/${name}/scheduled`);
			thunkApi.dispatch(requestActions.beforeFulfilled(indexScheduled.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(indexScheduled.typePrefix));
			console.log(error);
			return thunkApi.rejectWithValue({ payload: { companyName: name }, error });
		}
	}
);

const indexByIds = createAsyncThunk('orders/index-by-ids', async (payload: OrdersByIdsRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(indexByIds.typePrefix));
	try {
		const response = await API.post<OrdersByIdsRequest, Order[]>('/orders', payload);
		thunkApi.dispatch(requestActions.beforeFulfilled(indexByIds.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(indexByIds.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const addItem = createAsyncThunk('orders/add-item', async ({ id, ...payload }: OrderAddItemRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(addItem.typePrefix));
	try {
		const response = await API.post<Omit<OrderAddItemRequest, 'id'>, Order>(`/orders/${id}/add-item`, payload);
		thunkApi.dispatch(requestActions.beforeFulfilled(addItem.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(addItem.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { id, ...payload }, error });
	}
});

const deleteItem = createAsyncThunk(
	'orders/delete-item',
	async ({ id, ...payload }: OrderDeleteItemRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(deleteItem.typePrefix));
		try {
			const response = await API.delete<Omit<OrderDeleteItemRequest, 'id'>, Order>(`/orders/${id}/delete-item`);

			thunkApi.dispatch(requestActions.beforeFulfilled(deleteItem.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(deleteItem.typePrefix));
			console.log(error);
			return thunkApi.rejectWithValue({ payload: { id, ...payload }, error });
		}
	}
);

const update = createAsyncThunk('orders/update', async ({ id, ...payload }: OrderUpdateRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	try {
		const response = await API.put<Omit<OrderUpdateRequest, 'id'>, Order>(`/orders/${id}`, payload);

		thunkApi.dispatch(requestActions.beforeFulfilled(update.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(update.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { id, ...payload }, error });
	}
});

const sync = createAsyncThunk('orders/sync', async (companyName: string, thunkApi) => {
	thunkApi.dispatch(requestActions.started(sync.typePrefix));
	try {
		const response = await API.get<any, Order[]>(`/orders/${companyName}/sync`);

		thunkApi.dispatch(requestActions.beforeFulfilled(sync.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(sync.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { companyName }, error });
	}
});

const override = createAsyncThunk('orders/override', async (orderId: string, thunkApi) => {
	thunkApi.dispatch(requestActions.started(override.typePrefix));
	try {
		const response = await API.get<any, OkResponse>(`/orders/${orderId}/override`);

		thunkApi.dispatch(requestActions.beforeFulfilled(override.typePrefix));
		return response.data;
	} catch (error) {
		thunkApi.dispatch(requestActions.beforeRejected(override.typePrefix));
		console.log(error);
		return thunkApi.rejectWithValue({ payload: { orderId }, error });
	}
});

const ordersAsyncActions = {
	index,
	indexScheduled,
	indexByIds,
	addItem,
	deleteItem,
	update,
	sync,
	override,
};

export default ordersAsyncActions;
