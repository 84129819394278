import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import AServer from '../../../models/Admin/AServer';
import ASyncUser from '../../../models/Admin/ASyncUser';
import { useAppDispatch } from '../../../store';
import aSyncUsersAsyncActions from '../../../store/actions/aSyncUsers.action';
import RequestManager from '../../../store/request-manager';
import { useAServersState, useRequestState } from '../../../store/selectors';
import theme from '../../../theme';
const useStyles = makeStyles({
	root: {
		'& .MuiPaper-root': {
			backgroundColor: '#f1f1f1',
		},
	},
	container: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
});

export interface Props {
	user?: ASyncUser | null;
	open: boolean;
	onClose: () => void;
}

const CreateSyncUserModal: React.FC<Props> = ({ user, open, onClose }) => {
	const classes = useStyles();

	const dispatch = useAppDispatch();

	const aServersState = useAServersState();
	const requestState = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(requestState.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [serverId, setServerId] = React.useState<string>('');
	const [companyIds, setCompanyIds] = React.useState<string[]>([]);
	const [username, setUsername] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');

	const server = React.useMemo<AServer | null>(() => {
		const find = aServersState.list.find((element) => element.id === serverId);
		if (find) {
			return find;
		}

		return null;
	}, [aServersState.list, serverId]);

	React.useEffect(() => {
		if (!open || !user) {
			return;
		}

		setServerId(user.serverId);
		setCompanyIds(user.companyIds ?? []);
		setUsername(user.username);
	}, [open]);

	React.useEffect(() => {
		if (requestUpdatedAt === requestState.updatedAt) {
			return;
		}
		const RM = new RequestManager(requestState, dispatch);

		if (
			RM.isPending(aSyncUsersAsyncActions.store.typePrefix) ||
			RM.isPending(aSyncUsersAsyncActions.update.typePrefix)
		) {
			setIsLoading(true);
		}

		if (
			RM.isFinished(aSyncUsersAsyncActions.store.typePrefix) ||
			RM.isFinished(aSyncUsersAsyncActions.update.typePrefix)
		) {
			setIsLoading(false);

			onClose();
		}

		if (RM.isFulfilled(aSyncUsersAsyncActions.store.typePrefix)) {
			RM.consume(aSyncUsersAsyncActions.store.typePrefix);
		}

		if (RM.isFulfilled(aSyncUsersAsyncActions.update.typePrefix)) {
			RM.consume(aSyncUsersAsyncActions.update.typePrefix);
		}
	}, [requestUpdatedAt, requestState.updatedAt]);

	React.useEffect(() => {
		if (!open) {
			return;
		}

		return () => {
			setServerId('');
			setCompanyIds([]);
			setUsername('');
			setPassword('');
		};
	}, [open]);

	return (
		<Dialog className={classes.root} open={open} fullWidth maxWidth="sm">
			<Box flex={1} padding={'2em'}>
				<Grid spacing={2} container>
					<Grid item xs={12} sm={12} md={6}>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Server</InputLabel>
							<Select
								classes={{
									select: classes.container,
								}}
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={serverId}
								label="Server"
								onChange={(e) => setServerId(e.target.value)}
							>
								{aServersState.list.map((element) => (
									<MenuItem key={element.id} value={element.id}>
										{element.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Username'}
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							fullWidth
							size="small"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TextField
							label={'Password'}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							fullWidth
							type={'password'}
							size="small"
							variant="outlined"
						/>
					</Grid>
					{user !== null && user !== undefined && server !== null && (
						<Grid item xs={12} sm={12} md={12}>
							<FormGroup>
								{server.companies.map((element) => (
									<FormControlLabel
										key={element.id}
										control={
											<Checkbox
												value={element.id}
												checked={companyIds
													.map((e) => parseInt(e, 10))
													.includes(parseInt(element.id, 10))}
												onChange={(e) => {
													if (e.target.checked) {
														if (!companyIds.includes(element.id)) {
															setCompanyIds((prev) => [...prev, element.id]);
														}
													} else {
														const findIndex = companyIds.findIndex(
															(element1) =>
																parseInt(element1, 10) === parseInt(element.id, 10)
														);
														if (findIndex !== -1) {
															const newCompanyIds = [...companyIds];
															newCompanyIds.splice(findIndex, 1);

															setCompanyIds(newCompanyIds);
														}
													}
												}}
											/>
										}
										label={element.description}
									/>
								))}
							</FormGroup>
						</Grid>
					)}
				</Grid>
				<Box height={48} />
				<Grid spacing={2} container>
					<Grid item xs={6} sm={6} md={3}>
						<Button
							disabled={isLoading}
							onClick={() => {
								if (user) {
									dispatch(
										aSyncUsersAsyncActions.update({
											id: user.id,
											serverId,
											companyIds: companyIds,
											username,
											password,
										})
									);
								} else {
									dispatch(
										aSyncUsersAsyncActions.store({
											serverId,
											companyIds: null,
											username,
											password,
										})
									);
								}
							}}
							sx={{ height: '40px' }}
							fullWidth
							variant="contained"
						>
							{isLoading ? <CircularProgress size={18} /> : 'Save'}
						</Button>
					</Grid>
					<Grid item xs={6} sm={6} md={3}>
						<Button
							disabled={isLoading}
							sx={{ height: '40px' }}
							onClick={onClose}
							fullWidth
							variant="outlined"
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	);
};

export default CreateSyncUserModal;
