import {
	Box,
	Button,
	Divider,
	FormControlLabel,
	Menu,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	styled,
	Theme,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Image } from 'lib/components';
import NotAcceptedModal from 'lib/components/ErrorModals/NotAcceptedModal';
import { hexToRGB } from 'lib/utils/coli.util';
import Employee from 'models/Employee';
import { OrderSchedule } from 'models/Order';
import moment from 'moment';
import React, { useState } from 'react';
import { DraggableData } from 'react-draggable';
import { useParams } from 'react-router-dom';
import { IcFilter, IcFullscreen, IcScrollLeft, IcScrollRight } from 'res/icons';
import { useAppDispatch } from 'store';
import employeesAsyncActions from 'store/actions/employees.action';
import { useCalendarState, useEmployeesState, useSchedulerState, useUIState } from 'store/selectors';
import inventoryItemsAsyncActions from '../../../../store/actions/inventoryItems.action';
import orderPhasesAsyncActions from '../../../../store/actions/orderPhases.action';
import { employeesActions } from '../../../../store/slices/employees.slice';
import { schedulerActions } from '../../../../store/slices/scheduler.slice';
import { uiActions } from '../../../../store/slices/ui.slice';
import { SchedulerState } from '../../../../store/types';
import getUDFValue from '../../../getUDFValue';
import getWorkingHours from '../../../getWorkingHours';
import isOverlapping from '../../../isOverlapping';
import ScheduleBox, { DropableScheduleBox, StickyButton, WorkOrder } from './ScheduleBox';
import UserBox, { UserBoxItem } from './UserBox';
import UserDialog from './UserDialog';
import WorkDialog from './WorkDialog';

const useSchedularStyles = makeStyles((theme: Theme) => ({
	root: {
		'& table': {
			borderCollapse: 'collapse',
		},
		'& td, & th': {
			border: '0.5px solid #dddfe2',
		},
		'& .MuiTableContainer-root': {
			maxHeight: '100vh',
			overflowY: 'auto',
		},
		'& .drag-box': {
			padding: 30,
			background: '#ccc',
			width: 100,
			maxWidth: 100,
			cursor: 'move',
		},
	},
	tab: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		// justifyContent: 'center',
		// padding: '0 ',
		// border: '0.5px solid #dddfe2',
		height: 46,
		backgroundColor: '#e6eef6',
		color: '#0859a4',
		fontWeight: 600,
		// [theme.breakpoints.down('sm')]: { flexBasis: '100%' },
		'&.space': { flexBasis: 0 },
	},
	tabButton: {
		border: '0.5px solid #dddfe2',
		borderRadius: 0,
		flexBasis: 40,
		minWidth: 40,
		height: 46,
		backgroundColor: '#ffffff',
	},
	normalButton: {
		borderRadius: 0,
		flexBasis: 40,
		minWidth: 40,
		height: 46,
		padding: 0,
	},
	scrollableContent: {
		scrollBehavior: 'smooth',
		'&::-webkit-scrollbar': {
			width: 30,
			height: 30,
		},
		'&::-webkit-scrollbar-corner, &::-webkit-scrollbar-track': {
			backgroundColor: '#ffffff',
			border: '0.5px solid #dddfe2',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#afcdfb',
			'&:hover': {
				backgroundColor: '#81a4d8',
			},
			'&:active': {
				backgroundColor: '#5f83b8',
			},
		},
		'&::-webkit-scrollbar-button:single-button': {
			backgroundColor: '#ffffff',
			backgroundSize: 'cover',
			height: 30,
			width: 30,
			border: '0.5px solid #dddfe2',
			'&:hover': {
				backgroundColor: '#81a4d8',
			},
			'&:active': {
				backgroundColor: '#5f83b8',
			},
			'&:disabled': {
				backgroundColor: '#ced4da',
			},
		},
		'&::-webkit-scrollbar-button:single-button:vertical:decrement': {
			backgroundImage:
				"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(8, 89, 164)'><polygon points='50,40 15,60 25,60 50,50 75,60 85,60'/></svg>\")",
			'&:disabled': {
				backgroundImage:
					"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(130, 141, 158)'><polygon points='50,40 15,60 25,60 50,50 75,60 85,60'/></svg>\")",
			},
		},
		'&::-webkit-scrollbar-button:single-button:vertical:increment': {
			backgroundImage:
				"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(8, 89, 164)'><polygon points='50,60 15,40 25,40 50,50 75,40 85,40'/></svg>\")",
			'&:disabled': {
				backgroundImage:
					"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(130, 141, 158)'><polygon points='50,60 15,40 25,40 50,50 75,40 85,40'/></svg>\")",
			},
		},
		'&::-webkit-scrollbar-button:single-button:horizontal:decrement': {
			backgroundImage:
				"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(8, 89, 164)'><polygon points='40,50 60,15 60,25 50,50 60,75 60,85'/></svg>\")",
			'&:disabled': {
				backgroundImage:
					"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(130, 141, 158)'><polygon points='40,50 60,15 60,25 50,50 60,75 60,85'/></svg>\")",
			},
		},
		'&::-webkit-scrollbar-button:single-button:horizontal:increment': {
			backgroundImage:
				"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(8, 89, 164)'><polygon points='60,50 40,15 40,25 50,50 40,75 40,85'/></svg>\")",
			'&:disabled': {
				backgroundImage:
					"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(130, 141, 158)'><polygon points='60,50 40,15 40,25 50,50 40,75 40,85'/></svg>\")",
			},
		},
	},
}));

const StyledBox = styled(Box)(() => {
	const schedulerState = useSchedulerState();

	return {
		display: 'flex',
		flexDirection: 'row',
		flexShrink: 0,
		height: 30,
		alignItems: 'stretch',
		backgroundColor: '#ffffff',
		position: 'relative',
		'&.corner-box': {
			width: 200,
			height: 30,
			'& > .corner': {
				padding: '3px 5px',
				border: '0.5px solid #dddfe2',
				flexGrow: 1,
				fontSize: 14,
				fontWeight: 600,
				color: '#0859a4',
			},
		},
		'&.time-box': {
			maxWidth: `calc(${schedulerState.schedulerResolution.boxWidth}px * ${schedulerState.schedulerResolution.boxCount} + 30px)`,
			height: 30,
			'& > .left-time, & > .right-time': {
				border: '0.5px solid #dddfe2',
				boxSizing: 'border-box',
				flexBasis: 30,
				width: 30,
			},
			'& > .right-time': {
				borderRightWidth: '1px',
				borderBottomWidth: '1px',
			},
			'& > .time': {
				border: '0.5px solid #dddfe2',
				boxSizing: 'border-box',
				fontSize: 12,
				width: schedulerState.schedulerResolution.boxWidth,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
		},
	};
});

interface UrlParam {
	companyName: string;
}

export const Scheduler = () => {
	const classes = useSchedularStyles();

	const dispatch = useAppDispatch();

	const uiState = useUIState();
	const schedulerState = useSchedulerState();
	const employeesState = useEmployeesState();
	const calendarState = useCalendarState();

	const filterBtnRef = React.useRef(null);
	const scheduleBoxRef = React.useRef<HTMLDivElement>(null);

	const [schedulerInterval, setSchedulerInterval] =
		React.useState<SchedulerState['schedulerResolution']['mode']>('1-hour');
	const [filtersOpen, setFiltersOpen] = React.useState<boolean>(false);
	const [techFilter, setTechFilter] = React.useState<number>(0);
	const [userDialogOpen, setUserDialogOpen] = React.useState<boolean>(false);
	const [workDialogOpen, setWorkDialogOpen] = React.useState<boolean>(false);
	const [scrollX, setScrollX] = React.useState<number>(0);

	const [selectedWork, setSelectedWork] = useState<OrderSchedule>();
	const [selectedSchedule, setSelectedSchedule] = useState<Employee>();

	const [isErrorModalOpen, setIsErrorModalOpen] = React.useState<boolean>(false);
	const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

	const handleCloseErrorModal = React.useCallback(() => {
		setIsErrorModalOpen(false);
		setTimeout(() => {
			setErrorMessage(null);
		}, 1000);
	}, []);

	const startDate = React.useMemo<moment.Moment>(() => {
		return moment(calendarState.startDate, 'YYYY-MM-DD');
	}, [calendarState.startDate]);

	const endDate = React.useMemo<moment.Moment>(() => {
		return moment(calendarState.endDate, 'YYYY-MM-DD');
	}, [calendarState.endDate]);

	const employeesList = React.useMemo<Employee[]>(() => {
		return employeesState.list.filter((element) =>
			techFilter === 1
				? true
				: ['Available', 'On Lunch'].includes(getUDFValue(element.udfs, 'pg7_fld_001', 'Unavailable'))
		);
	}, [employeesState.updatedAt, employeesState.list, techFilter]);

	const handleDragStop = React.useCallback(
		(_: Event, data: DraggableData, employeeId: string, scheduleId: string, date: string) => {
			const employee = employeesState.list.find((element) => element.id === employeeId)!;
			const workOrder = employee.orders.find((element) => element.id === scheduleId)!;

			// 30 is padding
			let posX = Math.floor((data.x - 30) / schedulerState.schedulerResolution.oneMinuteWidth);

			let hour = 0;
			let minute = 0;

			while (posX >= 60) {
				posX -= 60;

				hour++;
			}
			minute = Math.floor(posX);

			// Snap to next hour.
			if (minute >= 57) {
				hour++;
				minute = 0;
			}

			if (minute <= 3) {
				minute = 0;
			} else if (minute >= 12 && minute <= 18) {
				minute = 15;
			} else if (minute >= 27 && minute <= 33) {
				minute = 30;
			} else if (minute >= 42 && minute <= 48) {
				minute = 45;
			}

			const findEmployeeIndex = employeesState.list.findIndex((element) => element.id === employeeId);
			const employeeTopY = 36 * findEmployeeIndex;

			// Set the decide point to the middle of dragging element (36 / 2 = 16)
			let newY = employeeTopY + data.y + 16;

			let newIndex = 0;
			while (newY >= 36) {
				newIndex++;

				newY -= 36;
			}

			// If index is invalid, keep actual one.
			if (newIndex < 0 || newIndex > employeesState.list.length - 1) {
				newIndex = findEmployeeIndex;
			}

			const momentIndexDate = moment.utc(date, 'YYYY-MM-DD');

			const workingHours = getWorkingHours(employeesState.list[newIndex], momentIndexDate.format('ddd'));

			const workingFrom = momentIndexDate.clone().hour(workingHours.from[0]).minute(workingHours.from[1]);
			const workingTo = momentIndexDate.clone().hour(workingHours.to[0]).minute(workingHours.to[1]);

			const startAtList = workOrder.startAt.split(',');
			const actualStartAtIndex = startAtList.findIndex((element) =>
				element.includes(momentIndexDate.format('YYYY-MM-DD'))
			);

			const startAt = moment.utc(startAtList[actualStartAtIndex]);
			const endAt = moment.utc(startAtList[actualStartAtIndex + 1]);

			const newStartAt = momentIndexDate.clone().hour(hour).minute(minute);

			// No changes has been made, allow for propagate down.
			if (newStartAt.isSame(startAt, 'minute') && findEmployeeIndex === newIndex) {
				return;
			}

			const workOrderLength = endAt.diff(startAt, 'minutes');

			const newEndAt = newStartAt.clone().add(workOrderLength, 'minutes');

			if (
				!newStartAt.isBetween(workingFrom, workingTo, 'minute', '[]') ||
				(!newEndAt.isBetween(workingFrom, workingTo, 'minute', '[]') && workOrderLength <= 60)
			) {
				setErrorMessage('You cannot schedule order here.');
				console.error('Cannot schedule here.');
				return;
			}

			for (let i = 0; i < startAtList.length - 1; i += 2) {
				const tempStartAt = moment.utc(startAtList[i]);
				const tempEndAt = moment.utc(startAtList[i + 1]);

				const tempWorkOrderLength = newStartAt.diff(startAt, 'minutes');

				const tempNewStartAt = tempStartAt.add(tempWorkOrderLength, 'minutes');
				const tempNewEndAt = tempEndAt.add(tempWorkOrderLength, 'minutes');

				if (isOverlapping(workOrder.id, employeesState.list[newIndex].orders, tempNewStartAt, tempNewEndAt)) {
					console.error('Cannot schedule here. Overlapping.');
					setErrorMessage("You cannot schedule order here, because it's overlapping with another one.");

					return;
				}
			}

			startAtList.splice(actualStartAtIndex, 1, newStartAt.format('YYYY-MM-DD HH:mm:ss'));
			startAtList.splice(actualStartAtIndex + 1, 1, newEndAt.format('YYYY-MM-DD HH:mm:ss'));

			const newWorkOrder = { ...workOrder };
			newWorkOrder.startAt = startAtList.join(',');

			if (findEmployeeIndex !== newIndex) {
				dispatch(
					employeesActions.removeOrderSchedule({
						employeeId,
						scheduleId: workOrder.id,
					})
				);
				dispatch(
					employeesActions.addOrderSchedule({
						employeeId: employeesState.list[newIndex].id,
						workOrder: newWorkOrder,
					})
				);
			} else {
				dispatch(
					employeesActions.addOrderSchedule({
						employeeId,
						workOrder: newWorkOrder,
					})
				);
			}

			dispatch(
				employeesAsyncActions.assignOrder({
					ids: [workOrder.orderId],
					employeeId: employeesState.list[newIndex].id,
					startAt: momentIndexDate.hour(hour).minute(minute).format(),
				})
			);
		},
		[employeesState.list, employeesState.updatedAt, schedulerState.schedulerResolution.oneMinuteWidth]
	);

	const handleUserDialogOpen = React.useCallback(() => {
		setUserDialogOpen(true);
	}, []);

	const handleUserDialogClose = React.useCallback(() => {
		setUserDialogOpen(false);
	}, []);

	const handleWorkDialogOpen = React.useCallback(() => {
		setWorkDialogOpen(true);
	}, []);

	const handleWorkDialogClose = React.useCallback(() => {
		setWorkDialogOpen(false);
	}, []);

	const { companyName } = useParams<UrlParam>();

	const getScheduledOrders = React.useCallback(
		(employee: Employee, date: string, dateIndex: number, rowIndex: number) => {
			if (
				employee.orders.filter((workOrder) => {
					const dates = workOrder.startAt
						.split(',')
						.map((element) => moment.utc(element, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'));

					const momentIndexDate = moment.utc(date, 'YYYY-MM-DD');
					const endAt = moment.utc(workOrder.endAt);
					if (dates.includes(momentIndexDate.format('YYYY-MM-DD')) || endAt.isSame(momentIndexDate, 'day')) {
						return true;
					}

					return false;
				}).length === 0
			) {
				return <WorkOrder rowIndex={rowIndex} employee={employee} date={date} dateIndex={dateIndex} />;
			}

			return employee.orders
				.filter((workOrder) => {
					const dates = workOrder.startAt
						.split(',')
						.map((element) => moment.utc(element, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'));

					const momentIndexDate = moment.utc(date, 'YYYY-MM-DD');
					const endAt = moment.utc(workOrder.endAt);
					if (dates.includes(momentIndexDate.format('YYYY-MM-DD')) || endAt.isSame(momentIndexDate, 'day')) {
						return true;
					}

					return false;
				})
				.map((item, orderIndex) => (
					<WorkOrder
						rowIndex={rowIndex}
						employee={employee}
						date={date}
						dateIndex={dateIndex}
						onStop={(ev: Event, data: DraggableData) =>
							handleDragStop(ev, data, employee.id, item.id, date)
						}
						id={`workorder-${rowIndex}-${orderIndex}`}
						key={`${item.id}-${employeesState.updatedAt}`}
						workOrder={item}
						handleWorkDialogOpen={() => {
							setSelectedWork(item);
							setSelectedSchedule(employee);
							handleWorkDialogOpen();
						}}
					/>
				));
		},
		[employeesState.updatedAt, employeesState.list, schedulerState.schedulerResolution]
	);

	React.useEffect(() => {
		dispatch(inventoryItemsAsyncActions.index({ name: companyName, page: 1, limit: 25 }));
		dispatch(employeesAsyncActions.index(companyName));
		dispatch(orderPhasesAsyncActions.index(companyName));
	}, []);

	React.useEffect(() => {
		if (selectedSchedule) {
			const findIndex = employeesState.list.findIndex((element) => element.id === selectedSchedule.id);
			if (findIndex !== -1) {
				setSelectedSchedule(employeesState.list[findIndex]);
			}
		}
	}, [employeesState.list, employeesState.updatedAt]);

	React.useEffect(() => {
		if (schedulerState.schedulerScrollTo === -1) {
			return;
		}

		if (scheduleBoxRef.current) {
			scheduleBoxRef.current.scrollTo({ left: schedulerState.schedulerScrollTo, behavior: 'smooth' });
		}

		dispatch(schedulerActions.scrollSchedulerTo(-1));
	}, [schedulerState.schedulerScrollTo]);

	React.useEffect(() => {
		if (scheduleBoxRef.current) {
			const now = moment();

			const hour = now.hour();
			const minute = now.minute();

			const posX =
				hour * schedulerState.schedulerResolution.oneHourWidth +
				minute * schedulerState.schedulerResolution.oneMinuteWidth;

			scheduleBoxRef.current.scrollTo({ left: posX, behavior: 'auto' });
		}
	}, [scheduleBoxRef]);

	React.useEffect(() => {
		if (scheduleBoxRef.current && employeesList.length > 0) {
			const now = moment();

			const employee = employeesList[0];

			const workingHours = getWorkingHours(employee, moment(calendarState.startDate, 'YYYY-MM-DD').format('ddd'));

			const hour = workingHours.amount[0] > 0 ? workingHours.from[0] : now.hour();
			const minute = workingHours.amount[0] > 0 ? workingHours.from[1] : now.minute();

			const posX =
				hour * schedulerState.schedulerResolution.oneHourWidth +
				minute * schedulerState.schedulerResolution.oneMinuteWidth;

			scheduleBoxRef.current.scrollTo({ left: posX, behavior: 'auto' });
		}
	}, [calendarState.startDate]);

	React.useEffect(() => {
		if (schedulerInterval === '15-minutes') {
			dispatch(
				schedulerActions.setSchedulerResolution({
					mode: schedulerInterval,
					boxWidth: 120,
					boxCount: 24 * 4,
					oneHourWidth: 120 * 4,
					oneMinuteWidth: 2 * 4,
					intervals: [...Array(24 * 4).keys()].map((e) =>
						moment()
							.hour(0)
							.minute(0)
							.add(e * 15, 'minute')
							.format('hh:mm A')
					),
				})
			);
		} else if (schedulerInterval === '30-minutes') {
			dispatch(
				schedulerActions.setSchedulerResolution({
					mode: schedulerInterval,
					boxWidth: 120,
					boxCount: 24 * 2,
					oneHourWidth: 120 * 2,
					oneMinuteWidth: 2 * 2,
					intervals: [...Array(24 * 2).keys()].map((e) =>
						moment()
							.hour(0)
							.minute(0)
							.add(e * 30, 'minute')
							.format('hh:mm A')
					),
				})
			);
		} else if (schedulerInterval === '1-hour') {
			dispatch(
				schedulerActions.setSchedulerResolution({
					mode: schedulerInterval,
					boxWidth: 120,
					boxCount: 24,
					oneHourWidth: 120,
					oneMinuteWidth: 2,
					intervals: [...Array(24).keys()].map((e) =>
						moment()
							.hour(0)
							.minute(0)
							.add(e * 60, 'minute')
							.format('hh:mm A')
					),
				})
			);
		} else if (schedulerInterval === 'days') {
			dispatch(
				schedulerActions.setSchedulerResolution({
					mode: schedulerInterval,
					boxWidth: 120,
					boxCount: calendarState.highlightedDates.length,
					oneHourWidth: 120 / 24,
					oneMinuteWidth: 2 / 24,
					intervals: [...Array(calendarState.highlightedDates.length).keys()].map((e) =>
						moment
							.utc(calendarState.startDate, 'YYYY-MM-DD')
							.hour(0)
							.minute(0)
							.add(e * 1, 'day')
							.format('Do MMM')
					),
				})
			);
		}
	}, [schedulerInterval, calendarState.highlightedDates]);

	React.useEffect(() => {
		if (schedulerState.schedulerResolution.mode === 'days' && calendarState.highlightedDates.length === 1) {
			setSchedulerInterval('1-hour');
		} else if (schedulerState.schedulerResolution.mode !== 'days' && calendarState.highlightedDates.length > 1) {
			setFiltersOpen(false);
			setSchedulerInterval('days');
		}
	}, [calendarState.highlightedDates]);

	React.useEffect(() => {
		if (schedulerState.schedulerResolution.mode === 'days' && calendarState.highlightedDates.length === 1) {
			setSchedulerInterval('1-hour');
		} else if (schedulerState.schedulerResolution.mode !== 'days' && calendarState.highlightedDates.length > 1) {
			setSchedulerInterval('days');
		}
	}, []);

	React.useEffect(() => {
		if (errorMessage !== null && !isErrorModalOpen) {
			setIsErrorModalOpen(true);
		}
	}, [errorMessage]);

	return (
		<Box className={classes.root}>
			<NotAcceptedModal message={errorMessage} open={isErrorModalOpen} onClose={handleCloseErrorModal} />
			<Box display="flex">
				<Box className={classes.tab}>
					<Typography fontWeight="600" sx={{ paddingLeft: '0.5em' }}>
						Scheduler
					</Typography>
					<Divider sx={{ marginRight: '2em', marginLeft: '2em' }} orientation="vertical" />
					<Box alignItems="center" columnGap="1em" display="flex">
						<Typography fontWeight="600">{`${startDate.format('DD')}/${startDate.format(
							'MM'
						)}`}</Typography>
						<Typography fontWeight="600">-</Typography>

						<Typography fontWeight="600">{`${endDate.format('DD')}/${endDate.format('MM')}`}</Typography>
					</Box>
				</Box>
				<Box className={`${classes.tab} space`}></Box>

				<Select
					size="small"
					value={techFilter}
					onChange={(e) => setTechFilter(e.target.value as number)}
					style={{
						minWidth: '120px',
						borderRadius: '0',
						borderTop: 'none',
					}}
				>
					<MenuItem value={0}>
						<Box display="flex" paddingX="1em" columnGap="1em">
							<Image src="icAvailableTech" />
							<Typography>Available Techs</Typography>
						</Box>
					</MenuItem>
					<MenuItem value={1}>
						<Box display="flex" paddingX="1em" columnGap="1em">
							<Image src="icAllTech" />
							<Typography>All Techs</Typography>
						</Box>
					</MenuItem>
				</Select>
				<Button
					disableElevation
					color={'secondary'}
					variant={'contained'}
					disabled={schedulerState.schedulerResolution.mode === 'days'}
					onClick={() => setFiltersOpen((prev) => !prev)}
					ref={filterBtnRef}
					className={classes.normalButton}
				>
					<IcFilter style={{ color: '#000000' }} />
				</Button>
				<Menu onBackdropClick={() => setFiltersOpen(false)} anchorEl={filterBtnRef.current} open={filtersOpen}>
					<Box style={{ width: 200, paddingLeft: 12, paddingRight: 12 }}>
						<RadioGroup
							aria-label="scheduler-interval"
							value={schedulerInterval}
							onChange={(_, value) => {
								setFiltersOpen(false);
								setSchedulerInterval(value as SchedulerState['schedulerResolution']['mode']);
							}}
							name="scheduler-interval"
						>
							<FormControlLabel value="15-minutes" control={<Radio />} label="15 Minutes" />
							<FormControlLabel value="30-minutes" control={<Radio />} label="30 Minutes" />
							<FormControlLabel value="1-hour" control={<Radio />} label="1 Hour" />
						</RadioGroup>
					</Box>
				</Menu>
				<Button
					disableElevation
					color={uiState.calenderVisible ? 'secondary' : 'primary'}
					variant={'contained'}
					onClick={() => {
						dispatch(uiActions.toggleFullscreenMode());
					}}
					className={classes.normalButton}
				>
					<IcFullscreen style={{ color: uiState.calenderVisible ? '#000000' : '#FFFFFF' }} />
				</Button>
			</Box>
			<Box style={{ position: 'relative', display: 'flex', flexDirection: 'row' }} width={'100%'}>
				<Box style={{ display: 'flex', flexDirection: 'column' }}>
					<StyledBox className="corner-box">
						<Typography className="corner">Time:</Typography>
					</StyledBox>
					<UserBox className="user-box">
						{employeesList.map((item, index) => {
							return (
								<UserBoxItem
									key={item.id}
									employee={item}
									color={index % 2 !== 0 ? '#DDE8F3' : '#FFFFFF'}
									onClick={() => {
										setSelectedSchedule(item);
										handleUserDialogOpen();
									}}
								/>
							);
						})}
					</UserBox>
				</Box>
				<Box
					ref={scheduleBoxRef}
					className={classes.scrollableContent}
					style={{
						flexGrow: 1,
						display: 'flex',
						flexDirection: 'column',
						height: !uiState.calenderVisible
							? 'calc(100vh - 65px - 50px - 30px - 55px + 116px)'
							: 'calc(100vh - 65px - 50px - 30px - 55px - 240px)',
					}}
					overflow={'auto'}
					component={'div'}
					onScroll={(e: any) => setScrollX(e.target.scrollLeft)}
				>
					<Box style={{ display: 'flex', flexDirection: 'row' }}>
						{/* {calendarState.highlightedDates.map((_, index) => ( */}
						<StyledBox
							// key={index}
							className="time-box"
							style={{
								maxWidth:
									// index === 0
									`calc(${schedulerState.schedulerResolution.boxWidth}px * ${schedulerState.schedulerResolution.boxCount} + 60px)`,
								// : `calc(${schedulerState.schedulerResolution.boxWidth}px * ${schedulerState.schedulerResolution.boxCount} + 30px)`,
							}}
						>
							{/* {index === 0 && <Typography className="left-time" />} */}
							<Typography className="left-time" />
							{schedulerState.schedulerResolution.intervals.map((element, index) => (
								<Typography key={index} className="time">
									{element}
								</Typography>
							))}
							{/* {schedulerState.schedulerResolution.mode !== 'days' && ( */}
							<Typography className="right-time" />
							{/* )} */}
						</StyledBox>
						{/* ))} */}
					</Box>
					<ScheduleBox>
						<Box position="relative" display="flex">
							{calendarState.highlightedDates.map((date, dateIndex) => (
								<Box
									position="relative"
									key={dateIndex}
									// onMouseLeave={handleMouseLeave}
								>
									{/* <Typography
										style={{
											position: 'absolute',
											left: -65,
											top: '30%',
											height: 30,
											transform: 'rotateZ(90deg)',
										}}
									>
										{moment.utc(date, 'YYYY-MM-DD').format('ll')}
									</Typography> */}
									{employeesList.length > 0 &&
										employeesList.map((employee, rowIndex) => (
											<DropableScheduleBox
												scheduleBoxRef={scheduleBoxRef}
												scheduleId={employee.id}
												date={date}
												dayIndex={dateIndex}
												scrollX={scrollX}
												style={{
													backgroundColor: hexToRGB(
														rowIndex % 2 !== 0 ? '#DDE8F3' : '#FFFFFF',
														0.4
													),
												}}
												key={employee.id}

												// onDragEnter={(ev: DragEvent<HTMLDivElement>) =>
												// 	handleDragEnter(ev, rowIndex)
												// }
												// onDragOver={(ev: DragEvent<HTMLDivElement>) =>
												// 	handleDragOver(ev, rowIndex)
												// }
												// onDrop={(ev: DragEvent<HTMLDivElement>) =>
												// 	handleDrop(ev, rowIndex)
												// }

												// onMouseEnter={(ev: MouseEvent<HTMLDivElement>) =>
												// 	handleMouseEnter(ev, rowIndex)
												// }
												// onMouseMove={(ev: MouseEvent<HTMLDivElement>) =>
												// 	handleMouseMove(ev, rowIndex)
												// }
												// onMouseUp={(ev: MouseEvent<HTMLDivElement>) =>
												// 	handleMouseUp(ev, rowIndex, schedule.id)
												// }
											>
												{getScheduledOrders(employee, date, dateIndex, rowIndex)}
											</DropableScheduleBox>
										))}
								</Box>
							))}
						</Box>
					</ScheduleBox>
				</Box>
				<Box
					style={{
						pointerEvents: 'none',
						position: 'absolute',
						top: 30,
						width: '100%',
						height: 'calc(100% - 60px)',
						zIndex: 2,
					}}
				>
					<StickyButton
						position="left"
						onClick={() => {
							if (scheduleBoxRef.current) {
								scheduleBoxRef.current.scrollBy({ left: -50, behavior: 'smooth' });
							}
						}}
					>
						<IcScrollLeft />
					</StickyButton>
					<StickyButton
						position="right"
						onClick={() => {
							if (scheduleBoxRef.current) {
								scheduleBoxRef.current.scrollBy({ left: 50, behavior: 'smooth' });
							}
						}}
					>
						<IcScrollRight />
					</StickyButton>
				</Box>
			</Box>
			{selectedWork && selectedSchedule && (
				<WorkDialog schedule={selectedWork} open={workDialogOpen} onClose={handleWorkDialogClose} />
			)}
			{selectedSchedule && (
				<UserDialog schedule={selectedSchedule} open={userDialogOpen} onClose={handleUserDialogClose} />
			)}
		</Box>
	);
};
