import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useWindowResize } from 'hooks';
import { Image } from 'lib/components';
import AppHeader from 'lib/components/AppHeader';
import { Breadcrumbs } from 'lib/components/Common';
import LogoutModal from 'lib/components/LogoutModal';
// import { RingLoader } from 'react-spinners';
import Company from 'models/Company';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IcHome } from 'res/icons';
import { useCompaniesState } from 'store/selectors';
import { useAppDispatch } from '../../store';
import { employeesActions } from '../../store/slices/employees.slice';
import { filtersActions } from '../../store/slices/filters.slice';
import { orderPhasesActions } from '../../store/slices/orderPhases.slice';
import { ordersActions } from '../../store/slices/orders.slice';

const useSelComStyles = makeStyles({
	root: {
		minHeight: '100%',
	},
	breadcrumbs: {
		paddingLeft: '2em',
	},
	heading: {
		marginBottom: 10,
		textTransform: 'uppercase',
		fontSize: 17,
		fontWeight: 800,
		color: '#0859a4',
	},
	title: {
		marginBottom: 10,
		fontSize: 36,
		fontWeight: 800,
	},
	companies: {
		marginBottom: 10,
		'& > .companies-item': {
			justifyContent: 'flex-start',
		},
	},
	btn: {
		'&.active': {
			backgroundColor: '#0859a433',
		},
		marginBottom: 10,
		textTransform: 'none',
		padding: '14px 32px',
		borderRadius: 32,
		fontSize: 12,
		fontWeight: 400,
		color: '#0859a4',
	},
	btnPrimary: {
		color: '#fff',
	},
});

export const SelectCompanScreen = () => {
	const dispatch = useAppDispatch();

	const [selectedCompany, setCompany] = useState<Company>();
	const classes = useSelComStyles();
	const { width } = useWindowResize();
	const companiesState = useCompaniesState();
	const [openLogoutModal, setLogoutModal] = useState(false);

	const closeModal = () => {
		setLogoutModal(false);
	};
	const openModal = () => {
		setLogoutModal(true);
	};

	React.useEffect(() => {
		dispatch(employeesActions.clear());
		dispatch(filtersActions.clear());
		dispatch(orderPhasesActions.clear());
		dispatch(ordersActions.clear());
	}, []);

	return (
		<Box className="flex h-full w-full">
			<LogoutModal open={openLogoutModal} onclose={closeModal} />
			<Box display="flex" flexDirection="column">
				<AppHeader />
				<Breadcrumbs className={classes.breadcrumbs}>
					<Link className="nav-item" to="/">
						<IcHome />
					</Link>
					<Link className="nav-item active" to="/selectcompany">
						Select Company
					</Link>
				</Breadcrumbs>
				<Box p={['10px', '10px 50px']} display="flex" alignItems="center" flexGrow={1}>
					<Box className={width < 900 ? 'flex items-center justify-center flex-col ' : ''}>
						<Typography variant="h3" className={classes.heading}>
							Select Company
						</Typography>
						<Box display="flex" width="full" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
							<Typography variant="h2" className={classes.title}>
								Company Name
							</Typography>
							{/* {companiesState.loading === 'pending' && (
								<Spinkit style={{ marginRight: '5em' }} name="chasing-dots" />
							)} */}
						</Box>

						<Box
							width={(width > 700 && width < 900) || width > 900 ? '82%' : '100%'}
							display="flex"
							flexWrap="wrap"
							className={classes.companies}
						>
							{/* {companiesState.loading === 'pending' ? <RingLoader /> : ''} */}
							{companiesState.list.map((item, index) => (
								<Button
									key={index}
									className={
										`${classes.btn} companies-item` +
										(item.id === selectedCompany?.id ? ' active' : '')
									}
									sx={{
										width: (width > 700 && width < 900) || width > 900 ? 600 : '100%',
									}}
									variant="outlined"
									onClick={() => {
										setCompany(item);
										localStorage.setItem('selectedCompany', JSON.stringify(item));
									}}
								>
									{item.description}
								</Button>
							))}
						</Box>
						<Box
							width={width > 900 || (width > 700 && width < 900) ? '82%' : '100%'}
							className="flex"
							columnGap="2em"
						>
							<Box className="w-full">
								<Button onClick={openModal} className={classes.btn} fullWidth variant="outlined">
									Exit
								</Button>
							</Box>
							<Box className="w-full">
								<Button
									className={`${classes.btn} ${classes.btnPrimary}`}
									fullWidth
									variant="contained"
									component={Link}
									to={`/company/${selectedCompany?.name}/dashboard`}
									disabled={!selectedCompany}
								>
									Select
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				className="ml-auto"
				sx={
					width > 900
						? {
								backgroundColor: '#d7e7ff',
								display: 'flex',
								alignItems: 'center',
								py: [5, 5, 0],
						  }
						: null
				}
			>
				<Image hidden={width < 900} src="ilMain" width="100%" />
			</Box>
		</Box>
	);
};
