import { Box, Button, IconButton, Table, TableBody, TableContainer, TableHead, TableRow, Theme } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { MouseEvent as ReactMouseEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IcArrowLeft, IcArrowRight, IcCapUp, IcSetting } from 'res/icons';
import { useAppDispatch } from 'store';
import { useCalendarState, useSchedulerState, useUIState } from 'store/selectors';
import { calendarActions } from 'store/slices/calendar.slice';
import { schedulerActions } from '../../../../store/slices/scheduler.slice';
import { uiActions } from '../../../../store/slices/ui.slice';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
	() => ({
		minWidth: 200,
		backgroundColor: '#e6eef6',
	})
);

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(() => ({
	padding: 0,
	minHeight: 0,

	'& .MuiAccordionSummary-content': {
		margin: 0,
		padding: 0,
	},
	'& .expand-icon': {
		transition: 'transform ease .3s',
		'.Mui-expanded &': {
			transform: 'rotate(180deg)',
		},
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
	padding: 0,
	borderTop: '1px solid rgba(0, 0, 0, .125)',
	'& .MuiMenuItem-root': {
		justifyContent: 'space-between',
		'&:not(:last-child)': {
			borderBottom: `1px solid #dddfe2`,
		},
	},
}));

const useCalendarStyles = makeStyles((theme: Theme) => ({
	root: {
		'& table': {
			borderCollapse: 'collapse',
		},
		'& td, & th': {
			border: '0.5px solid #dddfe2',
		},
	},
	tab: {
		display: 'flex',
		flexGrow: 1,
		overflow: 'hidden',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0 40px',
		border: '0.5px solid #dddfe2',
		height: 40,
		backgroundColor: '#e6eef6',
		color: '#0859a4',
		fontWeight: 600,
		// [theme.breakpoints.down('sm')]: { flexBasis: '100%' },
		'&.space': { flexBasis: 0 },
		'&.collapse': {
			padding: 0,
			maxWidth: 0,
			border: 'none',
			flexWrap: 'nowrap',
			opacity: 0,
		},
		transition: 'all ease .3s, opacity ease 0s',
	},
	tabButton: {
		border: '0.5px solid #dddfe2',
		borderRadius: 0,
		height: 40,
		flexBasis: 40,
		minWidth: 40,
		backgroundColor: '#ffffff',
	},
}));

const StyledTableCell = styled(TableCell)({
	padding: '5px 0',
	textAlign: 'center',
	'&.icon': {
		width: 40,
		maxWidth: 40,
	},
	width: '13.5%',
	'&.week': {
		width: '5.5%',
	},
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#ffffff',
		color: '#0859A4',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		'&.week': {
			fontSize: 12,
			color: '#0859A480',
		},
		'&.sunday': {
			color: '#22273080',
		},
		'&.previous, &.next': {
			color: '#22273033',
		},
	},
});

const StyledTableRow = styled(TableRow)({
	backgroundColor: '#ffffff',
});

const StyledIconButton = styled(IconButton)({
	border: '0.5px solid #0859a480',
	height: 20,
	width: 20,
	padding: '0 3.5px',
	margin: '0 10px',
	'& > svg': {
		width: '100%',
	},
});

export const Calendar = () => {
	const classes = useCalendarStyles();

	const dispatch = useAppDispatch();

	const schedulerState = useSchedulerState();
	const uiState = useUIState();
	const calendarState = useCalendarState();

	const [expand, setExpand] = React.useState<boolean>(true);
	const [weekMode, setWeekMode] = React.useState<'ALL' | 'WORK'>('WORK');

	const params = useParams<{
		companyName: string;
	}>();

	const currentDate = React.useMemo<moment.Moment>(() => {
		return moment(calendarState.current, 'YYYY-MM-DD');
	}, [calendarState.current]);

	const AMOUNT_OF_DAYS = React.useMemo<number>(() => {
		return weekMode === 'ALL' ? 7 : 5;
	}, [weekMode]);

	const toggleExpand = React.useCallback(() => {
		dispatch(uiActions.toggleCalendar());
		setExpand((prev) => !prev);
	}, []);

	const dates = React.useMemo<string[][]>(() => {
		const now = moment(calendarState.current, 'YYYY-MM-DD').date(1);
		const current = now.clone();

		while (current.day() !== 0) {
			current.subtract(1, 'day');
		}

		const dates: string[] = [];
		while (current.isSameOrBefore(now, 'month')) {
			if (weekMode === 'WORK' && (current.day() === 0 || current.day() === 6)) {
				current.add(1, 'day');
				continue;
			}

			dates.push(current.format('YYYY-MM-DD'));

			current.add(1, 'day');
		}

		while (current.day() !== 0) {
			if (weekMode === 'WORK' && (current.day() === 0 || current.day() === 6)) {
				current.add(1, 'day');
				continue;
			}

			dates.push(current.format('YYYY-MM-DD'));

			current.add(1, 'day');
		}

		const list: string[][] = [];
		while (dates.length > 0) {
			list.push(dates.splice(0, AMOUNT_OF_DAYS));
		}

		return list;
	}, [calendarState.current, weekMode, AMOUNT_OF_DAYS]);

	const handleDragEnd = (e: MouseEvent) => {
		e.preventDefault();
		dispatch(calendarActions.setIsDragging(false));

		window.removeEventListener('mouseup', handleDragEnd);
	};

	const handleDragStart = (e: ReactMouseEvent<HTMLElement>, date: moment.Moment) => {
		e.preventDefault();
		dispatch(calendarActions.setIsDragging(true));
		dispatch(calendarActions.setStartDate(date.format('YYYY-MM-DD')));
		dispatch(calendarActions.setEndDate(date.format('YYYY-MM-DD')));
		dispatch(
			calendarActions.setHighlightedDates({
				startDate: date.format('YYYY-MM-DD'),
				endDate: date.format('YYYY-MM-DD'),
			})
		);

		window.addEventListener('mouseup', handleDragEnd);
	};

	const handleDragChange = (e: ReactMouseEvent<HTMLElement>, date: moment.Moment) => {
		if (calendarState.isDragging) {
			dispatch(calendarActions.setEndDate(date.format('YYYY-MM-DD')));

			dispatch(
				calendarActions.setHighlightedDates({
					startDate: calendarState.startDate,
					endDate: date.format('YYYY-MM-DD'),
				})
			);
		}
	};

	const handleToday = React.useCallback(() => {
		const now = moment();
		dispatch(calendarActions.setCurrent(now.format('YYYY-MM-DD')));
		dispatch(calendarActions.setStartDate(now.format('YYYY-MM-DD')));
		dispatch(calendarActions.setEndDate(now.format('YYYY-MM-DD')));
		dispatch(
			calendarActions.setHighlightedDates({
				startDate: now.format('YYYY-MM-DD'),
				endDate: now.format('YYYY-MM-DD'),
			})
		);

		const hour = now.hour();
		const minute = now.minute();

		const posX =
			hour * schedulerState.schedulerResolution.oneHourWidth +
			minute * schedulerState.schedulerResolution.oneMinuteWidth;

		dispatch(schedulerActions.scrollSchedulerTo(posX));
	}, [dispatch, schedulerState.schedulerResolution]);

	const getTextColor = React.useCallback(
		(date: string) => {
			if (!calendarState.highlightedDates.includes(date)) {
				return '#000000';
			}

			return '#FFFFFF';
		},
		[calendarState.highlightedDates]
	);

	const getBackgroundColor = React.useCallback(
		(date: string) => {
			if (calendarState.highlightedDates.length === 0) {
				return '#FFFFFF';
			}

			if (
				calendarState.highlightedDates[0] === date ||
				calendarState.highlightedDates[calendarState.highlightedDates.length - 1] === date
			) {
				return '#0859a4';
			}

			if (calendarState.highlightedDates.includes(date)) {
				return '#0859a480';
			}

			return '#FFFFFF';
		},
		[calendarState.highlightedDates]
	);

	return (
		<Accordion expanded={uiState.calenderVisible}>
			<AccordionSummary>
				<Box height="40px" width="100%" display="flex" flexWrap="wrap">
					<Box className={classes.tab}>Calendar</Box>

					<Box className={classes.tab + (expand ? '' : ' collapse')}>
						<StyledIconButton
							onClick={() => {
								dispatch(
									calendarActions.setCurrent(
										currentDate.clone().subtract(1, 'month').format('YYYY-MM-DD')
									)
								);
							}}
						>
							<IcArrowLeft />
						</StyledIconButton>
						&nbsp;{currentDate.format('MMMM')}&nbsp;
						<StyledIconButton
							onClick={() => {
								dispatch(
									calendarActions.setCurrent(currentDate.clone().add(1, 'month').format('YYYY-MM-DD'))
								);
							}}
						>
							<IcArrowRight />
						</StyledIconButton>
					</Box>
					<Box className={classes.tab + (expand ? '' : ' collapse')}>
						<StyledIconButton
							onClick={() => {
								dispatch(
									calendarActions.setCurrent(
										currentDate.clone().subtract(1, 'year').format('YYYY-MM-DD')
									)
								);
							}}
						>
							<IcArrowLeft />
						</StyledIconButton>
						&nbsp;{currentDate.format('YYYY')}&nbsp;
						<StyledIconButton
							onClick={() => {
								dispatch(
									calendarActions.setCurrent(currentDate.clone().add(1, 'year').format('YYYY-MM-DD'))
								);
							}}
						>
							<IcArrowRight />
						</StyledIconButton>
					</Box>
					<Box className={classes.tab + (expand ? '' : ' collapse')}>
						<StyledIconButton onClick={() => setWeekMode((prev) => (prev === 'ALL' ? 'WORK' : 'ALL'))}>
							<IcArrowLeft />
						</StyledIconButton>
						&nbsp;Week: {weekMode === 'ALL' ? 'All' : 'Work'}&nbsp;
						<StyledIconButton onClick={() => setWeekMode((prev) => (prev === 'ALL' ? 'WORK' : 'ALL'))}>
							<IcArrowRight />
						</StyledIconButton>
					</Box>
					{/* <Box className={`${classes.tab} space` + (expand ? '' : ' collapse')}></Box> */}

					<Button onClick={handleToday} className={classes.tabButton} style={{ minWidth: 60 }}>
						Today
					</Button>
					<Button
						component={Link}
						to={`/company/${params.companyName}/scheduleboard/settings`}
						className={classes.tabButton}
					>
						<IcSetting />
					</Button>
					<Button className={classes.tabButton} onClick={toggleExpand}>
						<IcCapUp className="expand-icon" />
					</Button>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<TableContainer sx={{ height: '240px' }} className={classes.root}>
					<Table stickyHeader sx={{ width: '100%', height: '240px' }}>
						<TableHead>
							<StyledTableRow>
								<StyledTableCell className="week">Week</StyledTableCell>
								{weekMode === 'ALL' && <StyledTableCell>Sun</StyledTableCell>}
								<StyledTableCell>Mon</StyledTableCell>
								<StyledTableCell>Tue</StyledTableCell>
								<StyledTableCell>Wed</StyledTableCell>
								<StyledTableCell>Thu</StyledTableCell>
								<StyledTableCell>Fri</StyledTableCell>
								{weekMode === 'ALL' && <StyledTableCell>Sat</StyledTableCell>}
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{dates.map((row, rowIndex) => (
								<StyledTableRow key={rowIndex}>
									<StyledTableCell className="week">
										WK {moment(row[0], 'YYYY-MM-DD').week()}
									</StyledTableCell>
									{row.map((element: string, index: number) => {
										const date = moment(element, 'YYYY-MM-DD');

										return (
											<StyledTableCell
												key={index}
												className={[
													date.day() === 0 ? 'sunday' : '',
													date.isBefore(currentDate, 'month') ? 'previous' : '',
													date.isAfter(currentDate, 'month') ? 'next' : '',
												].join(' ')}
												onMouseDown={(e: ReactMouseEvent<HTMLElement>) =>
													handleDragStart(e, date)
												}
												onMouseEnter={(e: ReactMouseEvent<HTMLElement>) =>
													handleDragChange(e, date)
												}
												sx={{
													userSelect: 'none',
													color: getTextColor(element),
													backgroundColor: getBackgroundColor(element),
												}}
											>
												{date.date()}
											</StyledTableCell>
										);
									})}
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</AccordionDetails>
		</Accordion>
	);
};
