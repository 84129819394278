import images from './images';
import strings from './strings';
import languages from './languages';

const R = {
	images,
	strings,
	languages,
};

export default R;
