import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import API from '../../lib/utils/API';
import InventoryItem from '../../models/InventoryItem';
import InventoryItemsByIdsRequest from '../../network/requests/InventoryItemsByIdsRequest';
import InventoryItemsListRequest from '../../network/requests/InventoryItemsListRequest';
import SearchRequest from '../../network/requests/SearchRequest';
import PaginatedResponse from '../../network/responses/PaginatedResponse';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk(
	'inventoryItems/index',
	async ({ name, page, limit }: InventoryItemsListRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(index.typePrefix));
		try {
			const response = await API.get<any, AxiosResponse<PaginatedResponse<InventoryItem>>>(
				`/inventory-items/${name}?page=${page}&limit=${limit}`
			);

			thunkApi.dispatch(requestActions.beforeFulfilled(index.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(index.typePrefix));
			console.log(error);
			return thunkApi.rejectWithValue({ payload: {}, error });
		}
	}
);

const indexByIds = createAsyncThunk(
	'inventoryItems/index-by-ids',
	async ({ name, ...payload }: InventoryItemsByIdsRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(indexByIds.typePrefix));
		try {
			const response = await API.post<Omit<InventoryItemsByIdsRequest, 'name'>, InventoryItem[]>(
				`/inventory-items/${name}/by-ids`,
				payload
			);

			thunkApi.dispatch(requestActions.beforeFulfilled(indexByIds.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(indexByIds.typePrefix));
			console.log(error);
			return thunkApi.rejectWithValue({ payload: { companyName: name }, error });
		}
	}
);

const search = createAsyncThunk(
	'inventoryItems/search',
	async ({ companyName, ...payload }: SearchRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(search.typePrefix));
		try {
			const response = await API.post<Omit<SearchRequest, 'companyName'>, string[]>(
				`/inventory-items/${companyName}`,
				payload
			);

			thunkApi.dispatch(requestActions.beforeFulfilled(search.typePrefix));
			return response.data;
		} catch (error) {
			thunkApi.dispatch(requestActions.beforeRejected(search.typePrefix));
			console.log(error);
			return thunkApi.rejectWithValue({ payload: { companyName }, error });
		}
	}
);

const inventoryItemsAsyncActions = {
	index,
	indexByIds,
	search,
};

export default inventoryItemsAsyncActions;
