import ErrorResponse from '../network/responses/ErrorResponse';
import { requestActions } from './slices/request.slice';
import authenticationAsyncActions from './actions/authentication.action';
import { CPA } from './types';
import { toast } from 'react-hot-toast';

const postErrorRequest = (state: any, action: CPA<ErrorResponse>, initialState: any, displayError: boolean = true) => {
	if (displayError) {
		toast.error(action.payload.error.list[0].msg);
	}

	if (action.payload.error.status === 401) {
		action.dispatch(authenticationAsyncActions.signOut());
		state = initialState;
	}

	action.dispatch(
		requestActions.rejected({
			name: action.type,
			message: '',
			payload: { ...action.payload.error },
		})
	);

	return state;
};

export default postErrorRequest;
