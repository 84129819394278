import {
	Autocomplete,
	Button,
	CircularProgress,
	Dialog,
	DialogProps,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import React from 'react';
import { useParams } from 'react-router-dom';
import InventoryItem from '../../../../models/InventoryItem';
import { useAppDispatch } from '../../../../store';
import { useInventoryItemsState } from '../../../../store/selectors';
import inventoryItemsAsyncActions from '../../../../store/actions/inventoryItems.action';
import ordersAsyncActions from '../../../../store/actions/orders.action';
import isNumber from '../../../isNumber';
import useSearch from '../../../useSearch';

const useStyle = makeStyles({
	root: {
		'& .MuiPaper-root': {
			backgroundColor: '#f1f1f1',
		},
	},
});

interface Props {
	orderId: string;
	onClose: () => void;
	isEditing?: boolean;
}

const AddCustomItemModal: React.FC<DialogProps & Props> = ({ orderId, onClose, isEditing = false, ...props }) => {
	const classes = useStyle();

	const dispatch = useAppDispatch();

	const params = useParams<{
		companyName: string;
	}>();

	const inventoryItemsState = useInventoryItemsState();

	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState<InventoryItem[]>([...inventoryItemsState.list]);
	const [loading, setIsLoading] = React.useState<boolean>(false);

	const [itemId, setItemId] = React.useState<string | null>(null);
	const [partNo, setPartNo] = React.useState<string>('');
	const [description, setDescription] = React.useState<string>('');
	const [orderQty, setOrderQty] = React.useState<string>('');
	const [commitedQty, setCommitedQty] = React.useState<string>('');
	const [backorderQty, setBackorderQty] = React.useState<string>('');

	const [query, setQuery] = React.useState<string>('');
	const [from] = React.useState<number>(0);
	const [isScheduled, setIsScheduled] = React.useState<boolean>(false);

	useSearch(
		query,
		() => {
			setOptions([...inventoryItemsState.list]);
		},
		() => dispatch(inventoryItemsAsyncActions.search({ companyName: params.companyName, query, isScheduled, from }))
	);

	const canProceed = React.useMemo<boolean>(() => {
		if (isEditing && (partNo.toLowerCase().trim() === 'labour' || partNo.toLowerCase().trim() === 'labor')) {
			return false;
		}

		return true;
	}, [isEditing, partNo]);

	const handleSave = React.useCallback(async () => {
		if (!canProceed) {
			return;
		}

		await dispatch(
			ordersAsyncActions.addItem({
				id: orderId,
				itemId,
				partNo,
				description,
				orderQty,
				commitedQty,
				backorderQty,
			})
		);

		onClose();
	}, [canProceed, itemId, partNo, description, orderQty, commitedQty, backorderQty]);

	const handleSelect = React.useCallback((item: InventoryItem) => {
		setPartNo(item.partNo ?? '');
		setDescription(item.description ?? '');
	}, []);

	React.useEffect(() => {
		if (itemId !== null) {
			const item = inventoryItemsState.list.find((element) => element.id === itemId);
			if (item) {
				setPartNo(item.partNo ?? '');
				setDescription(item.description ?? '');
			}
		}
	}, [itemId]);

	React.useEffect(() => {
		if (!props.open) {
			return;
		}

		return () => {
			setItemId(null);
			setPartNo('');
			setDescription('');
			setOrderQty('');
			setCommitedQty('');
			setBackorderQty('');
		};
	}, [props.open]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	React.useEffect(() => {
		setIsLoading(inventoryItemsState.isPending);
	}, [inventoryItemsState.isPending]);

	React.useEffect(() => {
		if (inventoryItemsState.search.length > 0 && !inventoryItemsState.isPending) {
			dispatch(
				inventoryItemsAsyncActions.indexByIds({ name: params.companyName, ids: inventoryItemsState.search })
			);
		}
	}, [inventoryItemsState.search]);

	React.useEffect(() => {
		// if (inventoryItemsState.search.length > 0) {
		// if (!inventoryItemsState.isPending) {
		setOptions([...inventoryItemsState.list.filter((e) => inventoryItemsState.search.includes(e.id))]);
		// }
		// } else {
		// setOptions([...inventoryItemsState.list]);
		// }
	}, [inventoryItemsState.search]);

	return (
		<Dialog className={classes.root} fullWidth maxWidth="sm" {...props}>
			<Box minHeight={300}>
				<Grid alignItems="center" sx={{ padding: '1em', rowGap: '1em' }} container>
					<Grid item xs={3}>
						<Typography color="#0859a4" fontSize=".8rem" textAlign="center">
							Choose Item:
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<Autocomplete
							id="asynchronous-demo"
							// sx={{ width: 300 }}
							open={open}
							onOpen={() => {
								setOpen(true);
							}}
							onClose={() => {
								setOpen(false);
							}}
							filterOptions={(x) => x}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							getOptionLabel={(option: InventoryItem) => option.description ?? 'Unknown'}
							options={options}
							loading={loading}
							renderOption={(props, option) => {
								return (
									// @ts-ignore
									<div
										{...props}
										key={option.id}
										onClick={(e: any) => {
											if (props.onClick) {
												props.onClick(e);
											}
											handleSelect(option);
										}}
									>
										{option.description ?? 'Unknown'}
									</div>
								);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									onChange={(e) => setQuery(e.target.value)}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{loading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
						/>
						{/* <Select value={itemId} onChange={(e) => setItemId(e.target.value)} fullWidth size="small">
							{inventoryItemsState.list.map((item, index) => (
								<MenuItem value={item.id} key={index}>
									{item.description ?? 'Unknown'}
								</MenuItem>
							))}
						</Select> */}
					</Grid>
					<Grid item xs={3}>
						<Typography color="#0859a4" fontSize=".8rem" textAlign="center">
							Part No.:
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<TextField
							value={partNo}
							onChange={(e) => setPartNo(e.target.value.toUpperCase())}
							variant="outlined"
							fullWidth
							size="small"
						/>
					</Grid>
					<Grid item xs={3}>
						<Typography color="#0859a4" fontSize=".8rem" textAlign="center">
							Description:
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<TextField
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							variant="outlined"
							fullWidth
							size="small"
						/>
					</Grid>
					<Grid item xs={3}>
						<Typography color="#0859a4" fontSize=".8rem" textAlign="center">
							Order Qty.:
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<TextField
							value={orderQty}
							onChange={(e) => {
								setOrderQty(e.target.value);

								const value = e.target.value;
								if (isNumber(value) && isNumber(commitedQty)) {
									const parsed = parseInt(value, 10);

									setBackorderQty((parsed - parseInt(commitedQty, 10)).toFixed());
								}
							}}
							variant="outlined"
							fullWidth
							size="small"
						/>
					</Grid>
					<Grid item xs={3}>
						<Typography color="#0859a4" fontSize=".8rem" textAlign="center">
							Ship Qty.:
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<TextField
							value={commitedQty}
							onChange={(e) => {
								setCommitedQty(e.target.value);

								const value = e.target.value;
								if (isNumber(value) && isNumber(orderQty)) {
									const parsed = parseInt(value, 10);

									setBackorderQty((parseInt(orderQty, 10) - parsed).toFixed());
								}
							}}
							variant="outlined"
							fullWidth
							size="small"
						/>
					</Grid>
					<Grid item xs={3}>
						<Typography color="#0859a4" fontSize=".8rem" textAlign="center">
							B/O Qty:
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<TextField
							value={backorderQty}
							onChange={(e) => setBackorderQty(e.target.value)}
							variant="outlined"
							fullWidth
							size="small"
						/>
					</Grid>
				</Grid>
				<Grid sx={{ padding: '1em', columnGap: '1em' }} justifyContent="center" container>
					<Grid>
						<Button onClick={onClose} color="primary" variant="outlined">
							Cancel
						</Button>
					</Grid>
					<Grid>
						<Button disabled={!canProceed} onClick={handleSave} color="primary" variant="contained">
							Save
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	);
};

export default AddCustomItemModal;
