import { createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import Employee from 'models/Employee';
import ErrorResponse from 'network/responses/ErrorResponse';
import employeeAsyncActions from 'store/actions/employees.action';
import { CPA, PA, SliceState, Timing } from 'store/types';
import { OrderSchedule } from '../../models/Order';
import AttendanceStatues from '../../types/AttendanceStatues';
import authenticationAsyncActions from '../actions/authentication.action';
import ordersAsyncActions from '../actions/orders.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';

const initialState: SliceState<Employee> = {
	list: [],
	updatedAt: Timing.now(),
};

interface AddOrderSchedulePayload {
	employeeId: string;
	workOrder: OrderSchedule;
}

interface RemoveOrderSchedulePayload {
	employeeId: string;
	scheduleId: string;
}

const slice = createSlice({
	name: 'employees',
	initialState,
	reducers: {
		attendanceUpdate: (
			state: WritableDraft<typeof initialState>,
			action: PA<{
				employeeId: string;
				status: AttendanceStatues;
			}>
		) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.employeeId);
			if (findIndex !== -1) {
				const newEmployee = JSON.parse(JSON.stringify(state.list[findIndex])) as Employee;

				const findFieldIndex = newEmployee.udfs.findIndex((element) => element.name === 'pg7_fld_001');
				if (findFieldIndex !== -1) {
					newEmployee.udfs.splice(findFieldIndex, 1, {
						...newEmployee.udfs[findFieldIndex],
						value: action.payload.status as string,
					});

					state.list.splice(findIndex, 1, newEmployee);
					state.updatedAt = Timing.now();
				}
			}
		},
		addOrderSchedule: (state: WritableDraft<typeof initialState>, action: PA<AddOrderSchedulePayload>) => {
			const list = [...state.list] as Employee[];

			const index = list.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(action.payload.employeeId, 10)
			);
			if (index !== -1) {
				const orderIndex = list[index].orders.findIndex(
					(element) => parseInt(element.id, 10) === parseInt(action.payload.workOrder.id, 10)
				);

				if (orderIndex !== -1) {
					list[index].orders.splice(orderIndex, 1, action.payload.workOrder);
				} else {
					list[index].orders.push(action.payload.workOrder);
				}
			}

			state.list = list;
			state.updatedAt = Timing.now();
		},
		removeOrderSchedule: (state: WritableDraft<typeof initialState>, action: PA<RemoveOrderSchedulePayload>) => {
			const list = [...state.list];

			const index = list.findIndex(
				(element) => parseInt(element.id, 10) === parseInt(action.payload.employeeId, 10)
			);
			if (index !== -1) {
				const orderIndex = list[index].orders.findIndex(
					(element) => parseInt(element.id, 10) === parseInt(action.payload.scheduleId, 10)
				);

				if (orderIndex !== -1) {
					list[index].orders.splice(orderIndex, 1);
				}
			}

			state.list = list;
			state.updatedAt = Timing.now();
		},
		clear: () => initialState,
	},
	extraReducers: {
		[employeeAsyncActions.index.fulfilled.type]: (state, action: CPA<Employee[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[employeeAsyncActions.sync.fulfilled.type]: (state, action: CPA<Employee[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		// @ts-ignore
		[employeeAsyncActions.assignOrder.fulfilled.type]: (state, action: CPA<Employee>) => {
			action.dispatch(
				ordersAsyncActions.indexByIds({ ids: action.payload.orders.map((element) => element.orderId) })
			);

			const findIndex = state.list.findIndex(({ id }) => parseInt(id, 10) === parseInt(action.payload.id, 10));
			if (findIndex === -1) {
				state.list.push(action.payload);
			} else {
				state.list.splice(findIndex, 1, action.payload);
			}

			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[employeeAsyncActions.unassignOrder.fulfilled.type]: (state, action: CPA<Employee>) => {
			const findIndex = state.list.findIndex(({ id }) => parseInt(id, 10) === parseInt(action.payload.id, 10));
			if (findIndex === -1) {
				state.list.push(action.payload);
			} else {
				state.list.splice(findIndex, 1, action.payload);
			}

			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[employeeAsyncActions.update.fulfilled.type]: (state, action: CPA<Employee>) => {
			const list = [...state.list];
			const index = list.findIndex(({ id }) => parseInt(id, 10) === parseInt(action.payload.id, 10));

			if (index !== -1) {
				list[index] = action.payload;
			}

			state.list = list;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[employeeAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[employeeAsyncActions.sync.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[employeeAsyncActions.assignOrder.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[employeeAsyncActions.unassignOrder.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[employeeAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signIn.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
	},
});

export const employeesActions = slice.actions;

export default slice.reducer;
