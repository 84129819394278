import { createSlice } from '@reduxjs/toolkit';
import ErrorResponse from 'network/responses/ErrorResponse';
import { AAdminsState, CPA, Timing } from 'store/types';
import AAdmin from '../../models/Admin/AAdmin';
import aAdminsAsyncActions from '../actions/aAdmins.action';
import authenticationAsyncActions from '../actions/authentication.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';

const initialState: AAdminsState = {
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'aAdmins',
	initialState,
	reducers: {},
	extraReducers: {
		[aAdminsAsyncActions.index.fulfilled.type]: (state, action: CPA<AAdmin[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[aAdminsAsyncActions.store.fulfilled.type]: (state, action: CPA<AAdmin>) => {
			state.list.push(action.payload);
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[aAdminsAsyncActions.update.fulfilled.type]: (state, action: CPA<AAdmin>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
				state.updatedAt = Timing.now();
			}

			postRequest(action);
		},
		[aAdminsAsyncActions.destroy.fulfilled.type]: (state, action: CPA<Pick<AAdmin, 'id'>>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1);
				state.updatedAt = Timing.now();
			}

			postRequest(action);
		},
		[aAdminsAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aAdminsAsyncActions.store.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aAdminsAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[aAdminsAsyncActions.destroy.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
	},
});

export default slice.reducer;
