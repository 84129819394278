import { useQuery } from '@cyboticx/hooks';
import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Image } from 'lib/components';
import AppHeader from 'lib/components/AppHeader';
import { Breadcrumbs } from 'lib/components/Common';
import redirectTo from 'lib/utils/redirect';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IcChecked, IcFavicon, IcHome, IcSpire } from 'res/icons';
import { useAppDispatch } from 'store';
import { useAuthenticationState } from 'store/selectors';
import { removeAuthState, resetAuthState } from 'store/slices/authentication.slice';
import authenticationAsyncActions from 'store/actions/authentication.action';
import { Timing } from '../../store/types';
import SignInModal from './SignInModal';

const useSigninStyles = makeStyles(() => ({
	root: {
		minHeight: '100%',
	},
	breadcrumbs: {
		paddingLeft: '2em',
	},
	heading: {
		marginBottom: 10,
		textTransform: 'uppercase',
		'& > svg': {
			width: 25,
		},
		'& > h3': {
			fontSize: 17,
			fontWeight: 800,
			color: '#0859a4',
		},
	},
	title: {
		marginBottom: 10,
		fontSize: 36,
		fontWeight: 800,
	},
	desc: {
		marginBottom: 10,
		fontSize: 16,
		fontWeight: 400,
		lineHeight: 1.2,
		marginTop: '2em',
	},
	features: {
		display: 'flex',
		flexWrap: 'wrap',
		marginBottom: 10,
		marginTop: '2em',
		'& .features-item': {
			padding: '0 8px',
			marginRight: 0,
			width: 130,
			fontSize: 12,
			fontWeight: 600,
			lineHeight: 1.5,
		},
	},
	btn: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		marginTop: '2em',
		background: '#0059a414',
		border: '1px solid #0059a433',
		borderRadius: 32,
		padding: '15px 50px',
		// [theme.breakpoints.down('sm')]: {
		// 	padding: '10px 20px',
		// },
		textTransform: 'none',
		'& .MuiTypography-root': {
			fontSize: 12,
			fontWeight: 600,
			textAlign: 'center',
			color: 'black',
		},
	},
}));

export const SigninScreen = () => {
	const classes = useSigninStyles();
	const { pathname } = useLocation();
	const { replace } = useHistory();
	const redirectAfterLogin = useQuery().get('redirectTo');
	const dispatch = useAppDispatch();
	const authentication = useAuthenticationState();
	const [loginModalVisible, setLoginModalVisible] = useState(false);

	React.useEffect(() => {
		if (pathname === '/logout') {
			if (!authentication.isAuthenticated) {
				replace('/');
				return;
			}

			dispatch(authenticationAsyncActions.signOut());
			replace('/');
			return;
		}

		if (!authentication.isAuthenticated) return;

		if (Timing.isExpired(authentication.expiryAt)) {
			dispatch(removeAuthState());
			return;
		}

		if (redirectAfterLogin) {
			if (authentication.isAdministrator) {
				redirectTo(
					redirectAfterLogin.startsWith('/administrator/dashboard')
						? redirectAfterLogin
						: '/administrator/dashboard'
				);
				return;
			}

			redirectTo(
				redirectAfterLogin.startsWith('/administrator/dashboard') ? '/selectcompany' : redirectAfterLogin
			);
		} else {
			redirectTo(authentication.isAdministrator ? '/administrator/dashboard' : '/selectcompany');
		}
	}, [authentication]);

	const openLoginModal = React.useCallback(() => {
		setLoginModalVisible(true);
		dispatch(resetAuthState());
	}, []);

	const closeLoginModal = React.useCallback(() => {
		setLoginModalVisible(false);
		dispatch(resetAuthState());
	}, []);

	return (
		<Grid container spacing={0} className={classes.root}>
			<Grid item xs={12} md={6} component={Box} display="flex" flexDirection="column">
				<AppHeader />
				<Breadcrumbs className={classes.breadcrumbs}>
					<Link className="nav-item" to="/dashboard">
						<IcHome />
					</Link>
					<Link className="nav-item active" to="/dashboard">
						Enter
					</Link>
				</Breadcrumbs>
				<Box p={['10px', '10px 50px']} flexGrow={1} display="flex" alignItems="center">
					<Box>
						<Box display="flex" alignItems="center" className={classes.heading}>
							<IcFavicon />
							&nbsp;<Typography variant="h3">Schedule Pro</Typography>
						</Box>
						<Typography variant="h2" className={classes.title}>
							Get started with Schedule Pro
						</Typography>
						<Typography variant="h4" className={classes.desc}>
							The ultimate scheduling solutions for your company
						</Typography>
						<Box className={classes.features}>
							{['Work Orders', 'Schedule Board', 'Order Tracking', 'Easy to Use'].map((item, index) => (
								<Box key={index} display="flex" alignItems="center">
									<IcChecked />
									<Typography variant="body2" className="features-item">
										{item}
									</Typography>
								</Box>
							))}
						</Box>
						<Button className={classes.btn} fullWidth onClick={() => openLoginModal()}>
							<Grid container>
								<Grid
									item
									xs={12}
									sm={6}
									component={Box}
									display="flex"
									alignItems="center"
									justifyContent="center"
									mb={['10px', '0']}
								>
									<IcSpire />
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									component={Box}
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<Typography variant="body2" color="dark">
										Login with your spire account
									</Typography>
								</Grid>
							</Grid>
						</Button>
					</Box>
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
				sx={{
					backgroundColor: '#d7e7ff',
					display: 'flex',
					alignItems: 'center',
					py: [5, 5, 0],
				}}
			>
				<Image src="ilMain" width="100%" />
			</Grid>
			<SignInModal isOpen={loginModalVisible} onClose={closeLoginModal} />
		</Grid>
	);
};
