import { AppBar, Button, Toolbar } from '@mui/material';
import { Image } from 'lib/components';
import React from 'react';
import classes from './index.module.scss';
import { useAuthenticationState } from '../../../store/selectors';

function AppHeader() {
	const authenticationState = useAuthenticationState();

	return (
		<AppBar position="static" color="transparent" sx={{ height: '65px' }} className={classes['app-header']}>
			<Toolbar>
				<Button size="large" color="inherit" aria-label="menu" sx={{ width: 320, mr: 2 }}>
					<Image style={{ width: '100%', objectFit: 'contain' }} src="icLogo" />
				</Button>
			</Toolbar>
			{authenticationState.isAuthenticated && (
				<div
					style={{
						position: 'absolute',
						right: '0',
						padding: '10px',
						color: '#0859a4',
						textAlign: 'right',
					}}
				>
					<h3 style={{ fontSize: '20px', fontWeight: 'bold' }}>{authenticationState.username}</h3>
					<h6 style={{ textAlign: 'right' }}>
						{authenticationState.isAdministrator ? 'Administrator' : 'User'}
						<span style={{ paddingBottom: '20px' }}>
							<Image src="user" style={{ width: '20px', height: '20px', paddingBottom: '6px' }} />
						</span>
					</h6>
				</div>
			)}
		</AppBar>
	);
}
export default AppHeader;
