const languages: any = {
	en: {
		globals: {
			unauthorized: "You're not authenticated.",
			wrong_password: 'You entered wrong password.',
			unknown_user: 'We do not recognize that account.',
			wrong_otp: 'Entered OTP is incorrect. Please try again.',
			password_too_weak: 'Entered password is too weak.',
			expired_otp: 'Your OTP expired. Please request new one.',
			email_already_taken: 'This E-Mail address has been already taken.',
			incorrect_subtotal_amount:
				'Subtotal amount for this order does not match. Please check your products and try again.',
			incorrect_total_amount:
				'Total amount for this order does not match. Please check your products and try again.',
			invalid_delivery_address: 'Please check your delivery address.',
			unknown_dispensary: 'We could not find this dispensary. Please validate your cart.',
			prohibited_state: 'This State/Province does not allow for weed.',
			no_permit_for_state: 'You do not have prescription/permit for this state.',
			permit_rejected: 'Your prescription/permit got rejected. Please add new one.',
			permit_expired: 'Your prescription/permit validity expired. Please add new one.',
			unknown_product: 'We could not find some of your products. Please check your cart.',
			unknown_pricing_entry: 'We could not find proper price for some products. Please check your cart.',
			cannot_create_order_address:
				"Error 10: We're sorry but we could not fulfill the order. Please try again and or contact dispensary directly.",
			cannot_create_order:
				"Error 11: We're sorry but we could not fulfill the order. Please try again and or contact dispensary directly.",
		},
		authentication: {
			SignInScreen: {},
		},
	},
};

export default languages;
